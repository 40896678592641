
// import React, { useEffect, useState } from 'react';

// const CircularProgressBar = ({ sqSize = 200, Efficiency = 0, strokeWidth = 10, percentage = 25, FuelEfficiency=0 }) => {
//   const radius = (sqSize - strokeWidth) / 2;
//   const viewBox = `0 0 ${sqSize} ${sqSize}`;
//   const dashArray = radius * Math.PI * 2;
//   const dashOffset = dashArray - (dashArray * percentage) / 100;

//   return (
//     <svg width={sqSize} height={sqSize} viewBox={viewBox}>
//       <circle
//         className="circle-background"
//         cx={sqSize / 2}
//         cy={sqSize / 2}
//         r={radius}
//         strokeWidth={`${strokeWidth}px`}
//       />
//       <circle
//         className="circle-progress"
//         cx={sqSize / 2}
//         cy={sqSize / 2}
//         r={radius}
//         strokeWidth={`${strokeWidth}px`}
//         transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
//         style={{
//           stroke: Efficiency < 30 ? '#F2383A' : Efficiency < 60 ? '#F2C049' :'#0DBF4F',
//           strokeDasharray: dashArray,
//           strokeDashoffset: dashOffset,
//         }}
//       />
//       <text className="circle-text" x="50%" y="50%" dy=".3em" textAnchor="middle">
//         {`${Efficiency || 0} %`}
//       </text>
//     </svg>
//   );
// };

// export const Progressbar = ({Efficiency}) => {
//   const [percentage, setPercentage] = useState(0);
//   const [FuelEfficiency, setFuelEfficiency] = useState(45.58)

//   useEffect(()=>{
//     setPercentage(Efficiency)
//   },[Efficiency])


//   return (
//     <div>
//       <CircularProgressBar strokeWidth={14} sqSize={200} Efficiency={Efficiency} percentage={percentage} />
//     </div>
//   )
// }

import React, { useEffect, useState } from 'react';

const CircularProgressBar = ({ sqSize = 200, value = 0, strokeWidth = 10, percentage = 25 }) => {
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className="circle-progress"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          stroke: value < 30 ? '#F2383A' : value < 60 ? '#F2C049' : '#0DBF4F',
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      <text className="circle-text dark:fill-white" x="50%" y="50%" dy=".3em" textAnchor="middle">
        {`${value || 0} %`}
      </text>
    </svg>
  );
};

export const Progressbar = ({ value }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setPercentage(value)
  }, [value])

  return (
    <div>
      <CircularProgressBar strokeWidth={14} sqSize={200} value={value} percentage={percentage} />
    </div>
  )
}
