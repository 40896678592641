import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FleetView from "./pages/FleetView";
import FleetViewMap from "./pages/FleetViewMap";
import FuelNet from "./pages/FuelNet";
import VehiclesTwins from "./pages/VehiclesTwins";
import Notification from "./pages/Notification";
import Canvas from "./pages/Canvas";
import Econet from "./pages/Econet";
import BatterynetNew from "./pages/BatterynetNew";
import SingUp from "./pages/SingUp";
import Login from "./pages/Login";
import PrivateRoute from "./PrivateRoute";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="*" exact={true} element={<NotFound />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <FleetView />
              </PrivateRoute>
            }
          />
          <Route
            path="/fleet_view"
            element={
              <PrivateRoute>
                <FleetView />
              </PrivateRoute>
            }
          />
          <Route
            path="/fleet_view_map"
            element={
              <PrivateRoute>
                <FleetViewMap />
              </PrivateRoute>
            }
          />
          <Route
            path="/fuel_net"
            element={
              <PrivateRoute>
                <FuelNet />
              </PrivateRoute>
            }
          />
          <Route
            path="/digital_twins"
            element={
              <PrivateRoute>
                <VehiclesTwins />
              </PrivateRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <PrivateRoute>
                <Notification />
              </PrivateRoute>
            }
          />
          <Route
            path="/canvas"
            element={
              <PrivateRoute>
                <Canvas />
              </PrivateRoute>
            }
          />
          <Route
            path="/econet"
            element={
              <PrivateRoute>
                <Econet />
              </PrivateRoute>
            }
          />
          <Route
            path="/batterynet"
            element={
              <PrivateRoute>
                <BatterynetNew />
              </PrivateRoute>
            }
          />
          <Route path="/signup" element={<SingUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
