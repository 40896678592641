import React, { useEffect, useRef, useState } from "react";
import Layout from "../component/common/Layout/Layout";
import Header, { DefaultFleetName } from "../component/common/Header/Header";
import {
  Card,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import Icon from "@mdi/react";
import {
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
} from "@mdi/js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useAtom } from "jotai";
import { getAllVehicles } from "../redux/action/action";
import { useDispatch } from "react-redux";
import { UseContextData } from "../context/dateContext";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowUp } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import VehicleModel from "../component/common/popup/VehicleModel";

const FuelNet = () => {
  const { date } = UseContextData();
  const [fleetName, setfleetName] = useAtom(DefaultFleetName);
  const [tableData, setTableData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [itemsPerPage] = useState(25);
  const [vechicleId, setVehicleId] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref =useRef();
  const { t, i18n } = useTranslation();

  const getVehicles = async () => {
    const requestParams = { fleet: fleetName, date: date };
    try {
      const response = await dispatch(getAllVehicles(requestParams));
      const vehicleData = response?.payload?.data;
      if (vehicleData) {
        setTableData(vehicleData);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.log(error);
      setTableData([]);
    }
  };

  let vehicleListIntervalId = null;

  useEffect(() => {
    if (fleetName && date && date !== "Invalid Date") {
      getVehicles();
      vehicleListIntervalId = setInterval(() => {
        getVehicles();
      }, 15000000);
    }

    return () => {
      if (vehicleListIntervalId) {
        clearInterval(vehicleListIntervalId);
      }
    };
  }, [fleetName, date]);

  const handleSort = () => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newOrder);
    const sortedData = [...tableData].sort((a, b) => {
      const valueA = parseFloat(a.fuel_efficiency_max);
      const valueB = parseFloat(b.fuel_efficiency_max);

      if (newOrder === "asc") {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    });
    setTableData(sortedData);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    const newPage = Math.max(
      1,
      Math.min(pageNumber, Math.ceil(tableData.length / itemsPerPage))
    );
    setCurrentPage(newPage);
  };

  const { search } = UseContextData();
  const [selectedMenuOption, setSelectedMenuOption] = useState("");
  const filteredData = tableData.filter((item) => {
    if (selectedMenuOption === "Tire Issue") {
      return item.Low_tire_pressure === 1;
    } else if (selectedMenuOption === "Battery Issue") {
      return item.Low_battery_voltage === 1;
    }
    return true;
  });
  // const SearchData = !search ? currentItems : currentItems.filter((item) => { return (item?.vehicle_id.toLowerCase().includes(search.toLowerCase()) || item?.vehicle_type.toLowerCase().includes(search.toLowerCase())) })
  const SearchData = !search
    ? selectedMenuOption
      ? filteredData
      : currentItems
    : selectedMenuOption
    ? filteredData
    : tableData.filter((item) => {
        return (
          item?.vehicle_id.toLowerCase().includes(search.toLowerCase()) ||
          item?.vehicle_type.toLowerCase().includes(search.toLowerCase()) ||
          item?.fuel_efficiency_max.toFixed(2).includes(search.toLowerCase())
        );
      });

  const [showMenu, setShowMenu] = useState(false);

  const handleBrandMenuClick = () => {
    setShowMenu(!showMenu);
  };

  // const filteredData = tableData.filter((item) => {
  //   if (selectedMenuOption === 'Tire Issue') {
  //     return item.Low_tire_pressure === 1;
  //   } else if (selectedMenuOption === 'Battery Issue') {
  //     return item.Low_battery_voltage === 1;
  //   }
  //   return true;
  // });

  const handleModal = async (item,e) => {
    e.stopPropagation()
    setVehicleId(item);
    setShow(true);
  };

  const handleClick = async(e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setVehicleId(null);
    }
  };


  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [vechicleId]);

  return (
    <>
      <div className="overflow-hidden">
        <Layout>
          <div className="h-full">
            <Header selectedMenu={(selectedOption) => selectedOption} />
            <div className="flex items-center justify-between"></div>
            <div className="h-[calc(100%_-_40px)] pt-6 overflow-auto">
              <div className="flex justify-between items-center">
                <table className="w-full">
                  <tr>
                    <td className="w-[50%] py-4">
                      <div className="text-dark1 text-2xl dark:text-white">
                        {t("List of vehicles")}
                      </div>
                    </td>
                    <td className="py-4">
                      <div className="text-dark1 text-[18px] text-center dark:text-white">
                        {t("Recommendation to maximize efficiency")}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="overflow-y-auto">
                <Card className="h-full w-full overflow-auto relative">
                  <table className="w-full min-w-max table-auto text-left overflow-y-auto">
                    <thead>
                      <tr>
                        <th
                          key="Vehicle Id"
                          className="border-b border-[#d7d7d7] bg-white p-0 cursor-pointer text-center w-[100px] dark:bg-[#1d1d1d] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {t("Vehicle Id")}
                          </Typography>
                        </th>
                        <th
                          key="Type"
                          className="border-b border-[#d7d7d7] bg-white py-4 cursor-pointer text-center w-[80px] dark:bg-[#1d1d1d] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {t("Type")}
                          </Typography>
                        </th>
                        <th
                          key="Brand"
                          className="border-b border-[#d7d7d7] bg-white p-4 cursor-pointer text-center w-[80px] dark:bg-[#1d1d1d] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {t("Brand")}
                          </Typography>
                        </th>
                        <th
                          key="Fuel efficiency"
                          className="border-b border-[#d7d7d7] bg-white  py-4  cursor-pointer  text-center dark:bg-[#1d1d1d] dark:text-white"
                        >
                          <span className="flex items-center gap-10 justify-between w-[200px] ">
                            <Typography
                              variant="small"
                              color="#A3AED0"
                              className={`font-medium font-sm leading-none opacity-70 flex  items-center justify-between ${
                                i18n.language == "en"
                                  ? "whitespace-nowrap"
                                  : "twbalance"
                              }`}
                              onClick={() => setSelectedMenuOption("")}
                            >
                              {t("Fuel efficiency")}
                              {sortOrder === "asc" ? (
                                <Icon
                                  onClick={handleSort}
                                  path={mdiChevronUp}
                                  size={0.8}
                                />
                              ) : (
                                <Icon
                                  onClick={handleSort}
                                  path={mdiChevronDown}
                                  size={0.8}
                                />
                              )}
                            </Typography>
                            <span
                              className=" flex justify-between flex-col"
                              onClick={handleBrandMenuClick}
                            >
                              <Typography className="mx-auto font-sm leading-none opacity-70 text-center flex">
                                {t("safety")} /{" "}
                              </Typography>
                              <Typography className="mx-auto font-sm leading-none opacity-70 text-center flex">
                                {t("Maintanance")}
                              </Typography>
                              <div>
                                {showMenu && (
                                  <div className="flex gap-3 z-10 absolute top-[60px] bg-white rounded-lg shadow py-2 px-3 dark:bg-[#191919]">
                                    <img
                                      src="/assets/icons/batteryvoltage2.svg"
                                      onClick={() =>
                                        setSelectedMenuOption("Battery Issue")
                                      }
                                      alt="Vehiclesrunning2"
                                      className="inline-flex justify-center items-center w-6 h-6"
                                    />
                                    <img
                                      src="/assets/icons/Vehiclesrunning2.svg"
                                      onClick={() =>
                                        setSelectedMenuOption("Tire Issue")
                                      }
                                      alt="Vehiclesrunning2"
                                      className="inline-flex justify-center items-center w-6 h-6"
                                    />
                                  </div>
                                )}
                              </div>
                            </span>
                          </span>
                        </th>

                        <th
                          key="Recommended Speed"
                          className="border-b border-[#d7d7d7] bg-[#EFF0F5] py-4 px-1 cursor-pointer text-nowrap text-center w-[110px] dark:bg-[#191919] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {/* {t('Recommended ')} */}
                            {i18n.language === "es" ? (
                              <>
                                {"Velocidad Recomendada"} <br /> {"(Km/h)"}
                              </>
                            ) : (
                              <>{t("Recommended")}</>
                            )}
                          </Typography>
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {/* {t("Speed")} {t("(mph)")} */}
                            {i18n.language === "es" ? (
                              <></>
                            ) : (
                              <>
                                {t("Speed")} {t("(mph)")}
                              </>
                            )}
                          </Typography>
                        </th>
                        <th
                          key="Idle time"
                          className="border-b border-[#d7d7d7] bg-[#EFF0F5] py-4 cursor-pointer text-center w-[110px] dark:bg-[#191919] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {t("Idle time")} <br /> {t("(mins)")}
                          </Typography>
                        </th>
                        <th
                          key="FL"
                          className="border-b border-[#d7d7d7] bg-[#c8d9d663] py-4 pb-0 pt-6 cursor-pointer text-center w-[100px] dark:bg-[#191919] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {t("FL")}
                          </Typography>
                        </th>
                        <th
                          key="FR"
                          className="border-b border-[#d7d7d7] bg-[#c8d9d663] p-4 pb-0 pt-6 cursor-pointer text-center w-[100px] dark:bg-[#191919] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {t("FR")}
                          </Typography>
                        </th>
                        <th
                          key="RL"
                          className="border-b border-[#d7d7d7] bg-[#c8d9d663] p-4 pb-0 pt-6 cursor-pointer text-center w-[100px] dark:bg-[#191919] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {t("RL")}
                          </Typography>
                        </th>
                        <th
                          key="RR"
                          className="border-b border-[#d7d7d7] bg-[#c8d9d663] p-4 pb-0 pt-6 cursor-pointer text-center w-[100px] dark:bg-[#191919] dark:text-white"
                        >
                          <Typography
                            variant="small"
                            color="#A3AED0"
                            className="font-medium font-sm leading-none opacity-70 flex items-center justify-center"
                          >
                            {t("RR")}
                          </Typography>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(SearchData.length > 0 ? SearchData : tableData)?.map(
                        (item, index) => {
                          // (filteredTableData.length > 0 ? filteredTableData : tableData)?.map((item, index) => {
                          const isLast = index === currentItems.length - 1;
                          const classes = isLast
                            ? "px-0 dark:bg-[#252525] dark:text-white"
                            : "!px-0 border-b border-[#d7d7d7] text-center text-black dark:bg-[#1d1d1d] dark:text-white";
                          // const isMinus = item.fuel_efficiency_max.charAt(0) === "-"
                          // console.log(isMinus, "isMinusisMinusisMinus")
                          // let updatedItem;
                          // if (isMinus) {
                          //   updatedItem = item.fuel_efficiency_max.slice(1)
                          // } else {
                          //   updatedItem = item.fuel_efficiency_max
                          // }
                          return (
                            <tr
                              key={index}
                              className="hover:bg-[#6D3BE50D] dark:hover:bg-[#0d1120]"
                            >
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold cursor-pointer text-center dark:text-white"
                                  onClick={() => {
                                    navigate("/digital_twins");
                                  }}
                                >
                                  {item.vehicle_id}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-center dark:text-white"
                                >
                                  {t(`${item.vehicle_type}`)}
                                  {/* {t(item.vehicle_type)} */}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold flex justify-center"
                                >
                                  {item.make === "TOYOTA" ? (
                                    <img
                                      alt=""
                                      src="/assets/icons/toyota2.png"
                                      className="w-12 h-12"
                                    />
                                  ) : item.make === "FORD" ? (
                                    <img
                                      alt=""
                                      src="/assets/icons/ford1.png"
                                      className="w-12 h-12"
                                    />
                                  ) : item.make === "HONDA" ? (
                                    <img
                                      alt=""
                                      src="/assets/icons/honda.png"
                                      className="w-12 h-12"
                                    />
                                  ) : item.make === "HYUNDAI" ? (
                                    <img
                                      alt=""
                                      src="/assets/icons/hundai2.png"
                                      className="w-14 h-12"
                                    />
                                  ) : (
                                    item.make
                                  )}
                                </Typography>
                              </td>
                              <td className="px-4  border-b border-[#d7d7d7] text-center text-black relative w-[200px] dark:bg-[#1d1d1d] ">
                                <div className="flex  justify-between">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className={`font-bold ${
                                      item.fuel_efficiency_max < 30
                                        ? "text-[#E4232F]"
                                        : item.fuel_efficiency_max < 60
                                        ? "text-[#EBAE1B]"
                                        : "text-[#0DBF4F]"
                                    }`}
                                  >
                                    {item.fuel_efficiency_max.toFixed(2)} %
                                  </Typography>
                                  <div className="flex gap-2 absolute right-[45px] pr-5">
                                    {item.Low_battery_voltage === 1 ? (
                                      <>
                                        <img
                                          onClick={(e) =>
                                            handleModal(item?.vehicle_id,e)
                                          }
                                          src="/assets/icons/batteryvoltage2.svg"
                                          alt="Vehiclesrunning2"
                                          className="inline-flex justify-center items-center w-6 h-6 cursor-pointer"
                                          data-tooltip-id="alert-msg-2"
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {item.Low_tire_pressure === 1 ? (
                                      <img
                                        src="/assets/icons/Vehiclesrunning2.svg"
                                        alt="Vehiclesrunning2"
                                        className="inline-flex justify-center items-center w-6 h-6 cursor-pointer"
                                        data-tooltip-id="alert-msg"
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div ref={ref}>
                                      {vechicleId === item?.vehicle_id ? (
                                        <VehicleModel
                                          show={show}
                                          setShow={setShow}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className="bg-[#EFF0F5] px-4 border-b border-[#d7d7d7] text-center text-black dark:bg-[#191919] dark:text-white">
                                {item.speed}
                              </td>
                              <td className="bg-[#EFF0F5] px-4 border-b border-[#d7d7d7] text-center text-black dark:bg-[#191919] dark:text-white">
                                {item.Idle_time.toFixed(2)}
                              </td>
                              <td className="bg-[#c8d9d663] border-b border-[#d7d7d7] text-center text-black dark:bg-[#141414] dark:text-white">
                                <div className="flex items-center justify-center gap-[6px]">
                                  <Typography>
                                    {item.FL === 0 ? "--" : item.FL.toFixed(2)}
                                  </Typography>
                                  {item.FL > 0 ? <HiOutlineArrowUp /> : ""}
                                </div>
                              </td>
                              <td className="bg-[#c8d9d663] border-b border-[#d7d7d7] text-center text-black dark:bg-[#141414] dark:text-white">
                                <div className="flex items-center justify-center gap-[6px]">
                                  <Typography>
                                    {item.FR === 0 ? "--" : item.FR.toFixed(2)}
                                  </Typography>
                                  {item.FR > 0 ? <HiOutlineArrowUp /> : ""}
                                </div>
                              </td>
                              <td className="bg-[#c8d9d663] border-b border-[#d7d7d7] text-center text-black dark:bg-[#141414] dark:text-white">
                                <div className="flex items-center justify-center gap-[6px]">
                                  <Typography>
                                    {item.RL === 0 ? "--" : item.RL.toFixed(2)}
                                  </Typography>
                                  {item.RL > 0 ? <HiOutlineArrowUp /> : ""}
                                </div>
                              </td>

                              <td className="bg-[#c8d9d663] border-b border-[#d7d7d7] text-center text-black dark:bg-[#141414] dark:text-white">
                                <div className="flex items-center justify-center gap-[6px]">
                                  <Typography>
                                    {item.RR === 0 ? "--" : item.RR.toFixed(2)}
                                  </Typography>
                                  {item.RR > 0 ? <HiOutlineArrowUp /> : ""}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <Typography className="absolute right-[141px] top-[3px] opacity-70 text-[14px] dark:text-white">
                    {t("Tire Pressure")} {t("(psi)")}
                  </Typography>
                </Card>
                <div className="flex justify-end mt-4">
                  <div className="flex items-center">
                    <div className="text-dark1 font-medium mr-4 dark:text-white">{`${
                      indexOfFirstItem + 1
                    }-${indexOfLastItem} of ${currentPage}`}</div>
                    <div className="flex items-center">
                      <Icon
                        path={mdiChevronLeft}
                        size={"32px"}
                        className="mr-10 cursor-pointer dark:text-white"
                        onClick={() => paginate(currentPage - 1)}
                      />
                      <Icon
                        path={mdiChevronRight}
                        size={"32px"}
                        className="cursor-pointer dark:text-white"
                        onClick={() => paginate(currentPage + 1)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[50%] grid grid-cols-2   mt-4 mb-4 gap-[20px]">
                <div className="shadow-md bg-white dark:bg-[#444444]  rounded-lg p-[10px] flex gap-3">
                  <img
                    src="/assets/icons/batteryvoltage2.svg"
                    alt="Vehiclesrunning2"
                    className="inline-flex justify-center items-center w-6 h-6"
                  ></img>
                  <div>
                    <h2 className="text-[18px] dark:text-white">
                      {t("Battery health")}
                    </h2>
                    <h2 className="text-[18px] text-[#E4232F] dark:text-white ">
                      {t("low battery voltage")}
                    </h2>
                  </div>
                </div>
                <div className="shadow-md bg-white dark:bg-[#444444] rounded-lg p-[10px] flex gap-3">
                  <img
                    src="/assets/icons/Vehiclesrunning2.svg"
                    alt="Vehiclesrunning2"
                    className="inline-flex justify-center items-center w-6 h-6"
                  ></img>
                  <div>
                    <h2 className="text-[18px] dark:text-white">
                      Tires Health
                    </h2>
                    <h2 className="text-[18px] text-[#E4232F] dark:text-white">
                      {t("low tire pressure")}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
      <ReactTooltip
        id="alert-msg"
        className="z-20 !bg-white shadow-xl rou !py-4"
        place="top-start"
        content={
          <div className="flex items-start gap-2">
            <div>
              <img
                src="/assets/icons/Vehiclesrunning2.svg"
                alt="Vehiclesrunning2"
                className="w-6 h-6"
              />
            </div>
            <div>
              <div className="font-medium text-[#1F384C]">
                {t("Tires health")}
              </div>
              <div className="text-[#ED0606] text-sm">
                {t("low tire pressure")}
              </div>
            </div>
          </div>
        }
      />

      <VehicleModel show={show} setShow={setShow} />
      {/* <ReactTooltip
        id="alert-msg-2"
        className="z-20 !bg-white shadow-xl rou !py-4"
        place="top-start"
        content={
          <div className="flex items-start gap-2">
            <div>
              <img
                src="/assets/icons/batteryvoltage2.svg"
                alt="batteryvoltage2.svg"
                className="w-6 h-6"
              />
            </div>
            <div className="">
              <p className="text-[#F37E44] text-[16px] mb-3 leading-[18px] dark:text-white">
                {t("The vehicle is running on")} <br />
                <span className="text-[#F37E44] font-bold dark:text-white">
                  {t("low battery voltage")}
                </span>
              </p>

              <button className="max-w-[100%] px-2 py-2 bg-[#5DA3E9] text-white text-[14px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px] twrap">
                {t("Notify garages")}
              </button>
            </div>
          </div>
        }
      /> */}
    </>
  );
};

export default FuelNet;
