import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const contextData = createContext()

export const UseContextData = () => {
  return useContext(contextData)
}

const ValueData = ({ children }) => {
  const [date, setDate] = useState("2022-01-01")
  const [search, setSearch] = useState("")
  const [fleetName, setfleetName] = useState("midwest")
  const [selectedVin, setSelectedVin] = useState("")
  const currentDate = new Date()
  const [formatDate, setFormatDate] = useState(moment(currentDate).format('D MMM h:mm A'));  
  const [isDarkTheme, setIsDarkTheme] = useState("");
  const [user, setUser] = useState(null);
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedTimeStamp, setselectedTimeStamp] = useState("daily");
  const [selectedButton, setSelectedButton] = useState(["harsh_accelearation"]);


  useEffect(() => {
    switch (i18n.language) {
      case "en":
        setSelectedLanguage("English");
        break;
      case "es":
        setSelectedLanguage("Spanish");
        break;
      case "pt":
        setSelectedLanguage("Portuguese");
        break;
      case "zh":
        setSelectedLanguage("Chinese");
        break;
      default:
        setSelectedLanguage("English"); 
    }
  }, [i18n.language]);

  
  return (
    <contextData.Provider value={{ date, setDate, search, setSearch, selectedVin, setSelectedVin, fleetName, setfleetName, formatDate, setFormatDate, isDarkTheme, setIsDarkTheme, selectedLanguage, setSelectedLanguage, user, setUser, selectedTimeStamp, setselectedTimeStamp, selectedButton, setSelectedButton }}>
      {children}
    </contextData.Provider>
  )
}
export default ValueData