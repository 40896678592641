import React, { useState, Fragment, useRef, useEffect } from "react";
import Layout from "../component/common/Layout/Layout";
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import Icon from "@mdi/react";
import {
  mdiArrowDownThin,
  mdiArrowUpThin,
  mdiVolumeHigh,
  mdiStopCircleOutline,
} from "@mdi/js";
import ActiveVehicleChart from "../component/common/Chart/ActiveVehicleChart";
import Header from "../component/common/Header/Header";
import { useNavigate } from "react-router-dom";
import { UseManageData } from "../context/manageData";
import Popup from "../component/common/popup";
import FleetViewMap from "./FleetViewMap";
import HexagonChart from "../component/common/Chart/HexagonChart";
import { Wrapper } from "@googlemaps/react-wrapper";
import EfficiencyModal from "../component/common/popup/EfficiencyModal";
import PieChart from "../component/common/Chart/PieChart";
import ConsuptionModel from "../component/common/popup/ConsuptionModel";
import SpiderChart from "../component/common/Chart/SpiderChart";
import PieChartForecasted from "../component/common/Chart/PieChartForecasted";
import { Dialog, Transition } from "@headlessui/react";
import { Progressbar } from "./Progressbar";
import { UseContextData } from "../context/dateContext";
import axios from "axios";
import baseUrl from "../util/baseUrl";
import { useDispatch } from "react-redux";
import { getFleetOverviewDataByIndex } from "../redux/action/action";
import { useTranslation } from "react-i18next";
import NotifyModal from "../component/common/popup/NotifyModal";
import SubscribeForm from "../component/common/popup/SubscribeForm";

// const colorMap = ['#E4232F', '#F2C049', '#0DBF4F'];

// function getColor(index) {
//   return colorMap[(index + colorMap.length) % colorMap.length];
// }

const FleetView = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [openConsumption, setOpenConsumption] = useState(false);

  const [openTire, setOpenTire] = useState(false);
  const [tab, setTab] = useState(1);
  const [modal, setModal] = useState(false);

  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioObject, setAudioObject] = useState();

  const { stateData, setStateData } = UseManageData();
  const { isDarkTheme, setIsDarkTheme, selectedLanguage } = UseContextData();
  const [graphSelection, setgraphSelection] = useState("");
  const mapKey = process.env.REACT_APP_VITE_MAP_KEY;

  const IndexValue = useRef(1);
  const dispatch = useDispatch();
  const dataIntervalRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [openForm, setOpenForm] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      const requestParams = IndexValue.current;
      try {
        const response = await dispatch(
          getFleetOverviewDataByIndex(requestParams)
        );
        const responseData = response?.payload?.data;
        if (responseData) {
          setStateData({
            ...stateData,
            ActiveVehicles: +responseData["ActiveVehicles"] || 0,
            Batteries: +responseData["Batteries"] || 0,
            CO2_Emission: +responseData["CO2 Emission (Kg)"].toFixed(2) || 0,
            CostPerMile: +responseData["CPM_Cent"].toFixed(3) || 0,
            Distance: +responseData["Distance"].toFixed(2) || 0,
            Efficiency: +responseData["Efficiency"].toFixed(2) || 0,
            EnergySaving: +responseData["Energy_Savings(KJ)"] / 1000 || 0,
            Energy_KJ: +responseData["Energy(KJ)"] / 1000 || 0,
            FuelConsumed: +responseData["Fuel Consumed"].toFixed(2) || 0,
            LowBatteryVoltageVehicle:
              +responseData["Low_battery_voltage_vehicle"] || 0,
            LowTirePressureVehicle:
              +responseData["Low_tire_pressure_vehicle"] || 0,
            Mileage: +responseData["Mileage"].toFixed(3) || 0,
            NoofJobsDone: +responseData["No_of_Jobs_done"] || 0,
            SUV: +responseData["Pickup"] || 0,
            Sedan: +responseData["Sedan"] || 0,
            Tire_issue: +responseData["Tire_issue"] || 0,
            Van: +responseData["Van"] || 0,
            VehiclesNeedAttention:
              +responseData["Vehicles_need_attention"] || 0,
            chanage_CPM: +responseData["pct_change_CPM_Cent"] || 0,
            change_ActiveVehicles:
              +responseData["percentage_change ActiveVehicles"].toFixed(2) || 0,
            change_CO2EmissionKg:
              +responseData["percentage_change_CO2Emission(Kg_per_mile"] *
                1000 || 0,
            change_Efficiency:
              +responseData["percenatge_change_Efficiency"].toFixed(2) || 0,
            change_EnergyKJ:
              +responseData["percentage_change_Energy(KJ)_per_mile"] * 1000 ||
              0,
            change_FuelConsumed:
              +responseData["percentage_change_Fuel_per_mile"] * 1000 || 0,
            change_LowBatteryVoltageVehicle:
              +responseData[
                "percentage_change_Low battery voltage vehicle"
              ].toFixed(2) || 0,
            change_LowTirePressureVehicle:
              +responseData[
                "percentage_change_Low tire pressure vehicle"
              ].toFixed(2) || 0,
            change_Mileage: +responseData["percentage_change_Mileage"] || 0,
            change_NoofJobsDone:
              +responseData["percentage_change_NoofJobsDone"].toFixed(2) || 0,
            fuelPerMile: +responseData["Fuel_per_mile(Gallon)"] * 3.785 || 0,
            energyPerMile: +responseData["Energy_per_mile(KJ)"] * 3.785 || 0,
            emissionPerMile:
              +responseData["CO2_emission_per_mile(KJ)"].toFixed(2) || 0,
            driverHabitIssue: +responseData["driver_habit_issue"] || 0,
            efficiencyIssue: +responseData["efficiency_issue"] || 0,
            safety: +responseData["safety"] || 0,
            SuvIdletime: +responseData["Pickup_Idletime"] || 0,
            VanIdletime: +responseData["VAN_Idletime"] || 0,
            SedanIdletime: +responseData["SEDAN_Idletime"] || 0,
            SpeedRecommendation: +responseData["speed_recommendation"] || 0,
            SuvTirePressurePSI: +responseData["SUV_TirePressure_PSI"] || 0,
            VanTirePressurePSI: +responseData["VAN_TirePressure_PSI"] || 0,
            SedanTirePressurePSI: +responseData["SEDAN_TirePressure_PSI"] || 0,
            Future_Red_percent:
              +responseData["Future_Red_percent"].toFixed(2) || 0,
            Future_Green_percent:
              +responseData["Future_Green_percent"].toFixed(2) || 0,
            Future_Yellow_percent:
              +responseData["Future_Yellow_percent"].toFixed(2) || 0,
            VAN_Tire_Pressure_Saving:
              +responseData["VAN_Tire_Pressure_Saving"].toFixed(2) || 0,
            SUV_Tire_Pressure_Saving:
              +responseData["Pickup_Tire_Pressure_Saving"].toFixed(2) || 0,
            Sedan_Tire_Pressure_Saving:
              +responseData["Sedan_Tire_Pressure_Saving"].toFixed(2) || 0,
            VAN_Idling_Saving:
              +responseData["VAN_Idling_Saving"].toFixed(2) || 0,
            Pickup_Idling_Saving:
              +responseData["Pickup_Idling_Saving"].toFixed(2) || 0,
            Sedan_Idling_Saving:
              +responseData["Sedan_Idling_Saving"].toFixed(2) || 0,
            VAN_Speeding_saving:
              +responseData["VAN_Speeding_saving"].toFixed(2) || 0,
            SUV_Speeding_saving:
              +responseData["Pickup_Speeding_saving"].toFixed(2) || 0,
            Sedan_Speeding_saving:
              +responseData["Sedan_Speeding_saving"].toFixed(2) || 0,
            suv_lower_speed_limit:
              +responseData["suv_lower_speed_limit"].toFixed(2) || 0,
            suv_upper_speed_limit:
              +responseData["suv_upper_speed_limit"].toFixed(2) || 0,
            pickup_lower_speed_limit:
              +responseData["pickup_lower_speed_limit"].toFixed(2) || 0,
            pickup_upper_speed_limit:
              +responseData["pickup_upper_speed_limit"].toFixed(2) || 0,
            sedan_lower_speed_limit:
              +responseData["sedan_lower_speed_limit"].toFixed(2) || 0,
            sedan_upper_speed_limit:
              +responseData["sedan_upper_speed_limit"].toFixed(2) || 0,
            suv_FrontTire_pressure_recom:
              +responseData["suv_FrontTire_pressure_recom"].toFixed(2) || 0,
            suv_RearTire_pressure_recom:
              +responseData["suv_RearTire_pressure_recom"].toFixed(2) || 0,
            pickup_FrontTire_pressure_recom:
              +responseData["pickup_FrontTire_pressure_recom"].toFixed(2) || 0,
            pickup_RealTire_pressure_recom:
              +responseData["pickup_upper_speed_limit"].toFixed(2) || 0,
            sedan_FrontTire_pressure_recom:
              +responseData["sedan_FrontTire_pressure_recom"].toFixed(2) || 0,
            sedan_RearTire_pressure_recom:
              +responseData["sedan_RearTire_pressure_recom"].toFixed(2) || 0,
            suv_idletime_recom: +responseData["suv_idletime_recom"] || 0,
            pickup_idletime_recom: +responseData["pickup_idletime_recom"] || 0,
            sedan_idletime_recom: +responseData["sedan_idletime_recom"] || 0,
            Red_percent: +responseData["Red_percent"].toFixed(2) || 0,
            Green_percent: +responseData["Green_percent"].toFixed(2) || 0,
            Yellow_percent: +responseData["Yellow_percent"].toFixed(2) || 0,
            speed_issue: +responseData["speed_issue"].toFixed(2) || 0,
            Idling_issue: +responseData["Idling_issue"].toFixed(2) || 0,
            LowTirePressure_issue:
              +responseData["LowTirePressure_issue"].toFixed(2) || 0,
            Future_speed_issue:
              +responseData["Future_speed_issue"].toFixed(2) || 0,
            Future_Idling_issue:
              +responseData["Future_Idling_issue"].toFixed(2) || 0,
            Future_LowTirePressure_issue:
              +responseData["Future_LowTirePressure_issue"].toFixed(2) || 0,
          });
          IndexValue.current = response?.payload?.next_index;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
    dataIntervalRef.current = setInterval(() => {
      loadData();
      // setIndex(prevIndex => prevIndex + 1);
    }, 30000);
    return () => {
      if (dataIntervalRef.current) {
        clearInterval(dataIntervalRef.current);
      }
    };
  }, [dispatch]);

  // const [selectedLanguage, setSelectedLanguage] = useState("English");
  // const [selectedLanguageImroveNow, setSelectedLanguageImroveNow] =
  // useState("English");
  const speak = async (a1, a2) => {
    if (isSpeaking) {
      return;
    } else {
      setIsSpeaking(true);
      try {
        const text = `${a1}, Vehicles will need air today. ${a2}, Vehicles will need maintanance in 30 days.`;
        const response = await axios.get(
          `${baseUrl}/text_to_speech?text=${encodeURIComponent(
            text
          )}&language=${selectedLanguage}`,
          {
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], { type: "audio/mp3" });
        const blobUrl = URL.createObjectURL(blob);
        const audio = new Audio(blobUrl);
        audio.onended = () => {
          setIsSpeaking(false);
        };
        audio.play();
        setAudioObject(audio);
      } catch (err) {
        console.log("Error converting text to speech", err);
      }
    }
  };
  const stopAudio = () => {
    if (audioObject) {
      audioObject.pause();
      audioObject.currentTime = 0;
      setIsSpeaking(false);
      setAudioObject();
    }
  };
  // const [showMenu, setShowMenu] = useState(false);
  // const handleBrandMenuClick = () => {
  //   setShowMenu(!showMenu);
  // };

  // const handleLanguageSelect = (language) => {
  //   setSelectedLanguageImroveNow(language);
  //   setShowMenu(false);
  // };

  return (
    <Layout>
      <div className="h-full">
        <Header selectedMenu={(selectedOption) => selectedOption} />
        <div className="mt-7 mb-5 rounded-lg bg-box-pattern border border-white  dark:border-none p-6 dark:bg-box-pattern2">
          <h2 className="text-xl text-dark dark:text-white">
            {t("Efficiency profile")}
          </h2>
          <div className=" grid grid-cols-3 gap-[1rem]">
            <div className="flex flex-col justify-between items-center">
              <div className="text-center dark:text-white">
                {t("Fleet efficiency")}
              </div>
              <Progressbar value={stateData.Efficiency} />
              <div className="flex items-center mb-6">
                <div className="flex items-center">
                  <Icon
                    path={
                      stateData.change_Efficiency < 0
                        ? mdiArrowDownThin
                        : mdiArrowUpThin
                    }
                    size={1}
                    className={`${
                      stateData.change_Efficiency < 0
                        ? "text-[#E4232F]"
                        : "text-[#0DBF4F]"
                    }`}
                  />
                  <span
                    className={`${
                      stateData.change_Efficiency < 0
                        ? "text-[#E4232F]"
                        : "text-[#0DBF4F]"
                    } text-sm font-bold`}
                  >
                    {stateData.change_Efficiency} %
                  </span>
                </div>
                <div className="text-secondary ml-2 text-xs"></div>
              </div>
              <div className="w-full flex justify-center gap-3 items-center">
                <div>
                  <button
                    onClick={() => setOpen(!open)}
                    className="max-w-[168px] twrap mx-auto  px-4 py-2 bg-[#5DA3E9] text-white text-[16px]  font-bold w-full rounded-md flex items-center justify-center relative gap-[10px]"
                  >
                    {t("Improve now")}{" "}
                    <img src="/assets/icons/rightArrow.svg" alt="rightArrow" />
                  </button>
                </div>
                {/* onClick={() => setOpenTire(true)} */}
                {/* <div className="relative">
                  <Icon
                    path={mdiVolumeHigh}
                    size={"25px"}
                    className="dark:text-white cursor-pointer relative"
                    onClick={handleBrandMenuClick}
                  />
                  <div className="absolute bottom-[30px] left-0">
                    {showMenu && (
                      <>
                        <div className="bg-white rounded-md max-w-[130px] text-nowrap w-full p-2 dark:bg-[#202020] dark:text-white">
                          <p className="cursor-pointer py-1" onClick={() => handleLanguageSelect("English")}>English</p>
                          <p className="cursor-pointer py-1" onClick={() => handleLanguageSelect("Spanish")}>Spanish</p>
                          <p className="cursor-pointer py-1" onClick={() => handleLanguageSelect("Portuguese")}>Portuguese</p>
                          <p className="cursor-pointer py-1" onClick={() => handleLanguageSelect("Chinese")}>Chinese</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>             */}
              </div>
            </div>
            <div className="flex flex-col justify-between items-center">
              {/* <div>
                <span className='flex items-end gap-1'>Efficiency <span className='text-[20px] font-medium'>{stateData.Efficiency}</span><div className='flex items-center'>
                  <Icon path={stateData.change_Efficiency < 0 ? mdiArrowDownThin : mdiArrowUpThin} size={1} className={`${stateData.change_Efficiency < 0 ? 'text-[#E4232F]' : "text-[#0DBF4F]"}`} />
                  <span className={`${stateData.change_Efficiency < 0 ? 'text-[#E4232F]' : "text-[#0DBF4F]"} text-sm font-bold`}>{stateData.change_Efficiency} %</span>
                </div> <span className='text-gray-700 text-[12px]'>vs yesterday</span></span>
              </div> */}
              <div className="w-full">
                <div className="p-4 flex items-center justify-between bg-white dark:bg-[#444444] shadow-xl rounded-lg mb-4">
                  <div className="font-bold text-dark dark:text-white">
                    {t("Total vehicles")}
                  </div>
                  <div className="flex items-center gap-[4px]">
                    <div className="text-dark font-medium text-2xl dark:text-white">
                      9000
                    </div>
                    <div className="p-[10px] rounded-full">
                      <img src="/assets/icons/car.svg" alt="caricon" />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="p-4 bg-white rounded-lg w-1/2 shadow-xl dark:bg-[#444444]">
                    <div className="font-bold text-dark mb-4 dark:text-white">
                      {t("Distribution")}
                    </div>
                    <div className="flex">
                      <div className="h-[120px] w-3">
                        <div className='h-[55.56%] w-1.5 bg-[#6bc0b3] relative before:bg-[#6bc0b3] vertical-progress before::content-[""] before:absolute before:w-3 before:h-0.5'></div>
                        <div className='h-[22.22%] w-1.5 bg-[#b7a058] relative before:bg-[#b7a058] vertical-progress before::content-[""] before:absolute before:w-3 before:h-0.5'></div>
                        <div className='h-[22.22%] w-1.5 bg-[#7a9abc] relative before:bg-[#7a9abc] vertical-progress before::content-[""] before:absolute before:w-3 before:h-0.5'></div>
                      </div>
                      <div className="h-[120px] flex-grow pl-2">
                        <div className="h-[55.56%] text-sm dark:text-white">
                          {t("Van's")} 55.56%
                        </div>
                        <div className="h-[22.22%] text-sm dark:text-white">
                          {t("Sedan's")} 22.22%
                        </div>
                        <div className="h-[22.22%] text-sm dark:text-white">
                          {t("Pickup Truck's")} 22.22%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-4 bg-white dark:bg-[#444444] rounded-lg w-1/2 shadow-xl">
                    <div className="flex flex-col justify-between items-start">
                      <div
                        className="cursor-pointer font-bold text-dark mb-4 hover:text-[#5DA3E9] dark:hover:text-[#5DA3E9] dark:text-white"
                        onClick={() => {
                          navigate("/fuel_net");
                        }}
                      >
                        {t("Active Vehicles")}
                      </div>
                      <div>
                        <div className="text-dark font-medium text-2xl dark:text-white">
                          {stateData.ActiveVehicles}
                        </div>
                        <div className="flex items-center mb-6">
                          <div className="flex items-center">
                            <Icon
                              path={
                                stateData.change_ActiveVehicles < 0
                                  ? mdiArrowDownThin
                                  : mdiArrowUpThin
                              }
                              size={1}
                              className={`${
                                stateData.change_ActiveVehicles < 0
                                  ? "text-[#E4232F]"
                                  : "text-[#0DBF4F]"
                              }`}
                            />
                            <span
                              className={`${
                                stateData.change_ActiveVehicles < 0
                                  ? "text-[#E4232F]"
                                  : "text-[#0DBF4F]"
                              } text-sm font-bold`}
                            >
                              {stateData.change_ActiveVehicles} %
                            </span>
                          </div>
                          <div className="text-secondary ml-2 text-xs"></div>
                        </div>
                      </div>
                      <Popover>
                        <PopoverHandler>
                          <div>
                            <img
                              src="/assets/icons/graph.svg"
                              alt="graph"
                              className="cursor-pointer"
                              onClick={() =>
                                setgraphSelection("ActiveVehicles")
                              }
                            />
                          </div>
                        </PopoverHandler>
                        <PopoverContent className="z-30 w-[350px] dark:bg-[#444444] dark:border-[#68DCB5]">
                          <ActiveVehicleChart
                            graphSelection={graphSelection}
                            isDarkTheme={isDarkTheme}
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                </div>
                {/* <button onClick={() => { navigate('/fleet_view_map') }} className='mt-[1.5rem] px-4 py-2 bg-[#5DA3E9] text-white text-[16px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px]'> */}
                <button
                  onClick={() => setOpenConsumption(!openConsumption)}
                  className="mt-[1.5rem] px-4 py-2 bg-[#5DA3E9] text-white text-[16px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px]"
                >
                  <img
                    src="/assets/icons/Modo_de_aislamiento(1).svg"
                    alt="vector"
                    className=""
                  />
                  {t("Consumption profile")}
                </button>
              </div>
              <ConsuptionModel
                graphSelection={graphSelection}
                setgraphSelection={setgraphSelection}
                stateData={stateData}
                open={openConsumption}
                setOpen={setOpenConsumption}
              />
            </div>
            <div>
              <div className="flex gap-4">
                <div className="p-4 bg-white dark:bg-[#444444] rounded-lg w-1/2 shadow-xl">
                  <div className="flex flex-col justify-between ">
                    <div className="flex items-center justify-between">
                      <div className="font-bold twrap text-dark dark:text-white">
                        {t("Cost per mile")}
                      </div>
                      <div className="p-[8px_7px_7px_10px] rounded-full">
                        <img src="/assets/icons/Costmile.svg" alt="Costmile" />
                      </div>
                    </div>
                    <div>
                      <div className="text-dark font-medium text-2xl mb-2.5 dark:text-white">
                        {stateData.CostPerMile}{" "}
                        <sub className="text-secondary text-xs dark:text-white">
                          {t("Cents")}
                        </sub>
                      </div>
                      <div className="flex items-center mb-1">
                        <div className="flex items-center">
                          <Icon
                            path={
                              stateData.chanage_CPM < 0
                                ? mdiArrowDownThin
                                : mdiArrowUpThin
                            }
                            size={1}
                            className={`${
                              stateData.chanage_CPM < 0
                                ? "text-[#0DBF4F]"
                                : "text-[#E4232F]"
                            }`}
                          />
                          <span
                            className={`${
                              stateData.chanage_CPM < 0
                                ? "text-[#0DBF4F]"
                                : "text-[#E4232F]"
                            } text-sm font-bold`}
                          >
                            {stateData.chanage_CPM.toFixed(3)} %
                          </span>
                        </div>
                        <div className="text-secondary ml-2 text-xs"></div>
                      </div>
                    </div>
                    <Popover>
                      <PopoverHandler>
                        <div>
                          <img
                            src="/assets/icons/graph.svg"
                            alt="graph"
                            className="cursor-pointer"
                            onClick={() => setgraphSelection("Cost per mile")}
                          />
                        </div>
                      </PopoverHandler>
                      <PopoverContent className="z-30 w-[350px] dark:bg-[#444444] dark:border-[#68DCB5]">
                        <ActiveVehicleChart
                          graphSelection={graphSelection}
                          isDarkTheme={isDarkTheme}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
                <div className="p-4 bg-white dark:bg-[#444444] rounded-lg w-1/2 shadow-xl">
                  <div className="flex flex-col justify-between">
                    <div className="flex justify-between items-center">
                      <div className="font-bold text-dark dark:text-white">
                        {t("Mileage")}
                      </div>
                      <div className="p-[8px_8px_7px_9px] rounded-full">
                        <img src="/assets/icons/Truck.svg" alt="Truck" />
                      </div>
                    </div>
                    <div>
                      <div className="flex mb-2.5">
                        <div className="text-dark font-medium text-2xl mr-2 dark:text-white">
                          {stateData.Mileage}
                        </div>
                        <div className="max-w-[80px] w-full text-xs text-secondary dark:text-white">
                          {t("Miles per gallon")}
                        </div>
                      </div>
                      <div className="flex items-center mb-1">
                        <div className="flex items-center">
                          <Icon
                            path={
                              stateData.change_Mileage < 0
                                ? mdiArrowDownThin
                                : mdiArrowUpThin
                            }
                            size={1}
                            className={`${
                              stateData.change_Mileage < 0
                                ? "text-[#0DBF4F]"
                                : "text-[#E4232F]"
                            }`}
                          />
                          <span
                            className={`${
                              stateData.change_Mileage < 0
                                ? "text-[#0DBF4F]"
                                : "text-[#E4232F]"
                            } text-sm font-bold`}
                          >
                            {stateData.change_Mileage.toFixed(3)} %
                          </span>
                        </div>
                        <div className="text-secondary ml-2 text-xs"></div>
                      </div>
                    </div>
                    <Popover>
                      <PopoverHandler>
                        <div>
                          <img
                            src="/assets/icons/graph.svg"
                            alt="graph"
                            className="cursor-pointer"
                            onClick={() => setgraphSelection("Mileage")}
                          />
                        </div>
                      </PopoverHandler>
                      <PopoverContent className="z-30 w-[350px] dark:bg-[#444444] dark:border-[#68DCB5]">
                        <ActiveVehicleChart
                          graphSelection={graphSelection}
                          isDarkTheme={isDarkTheme}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              </div>

              <div className="p-[10px_16px_10px_16px] flex gap-[7px] items-start bg-white dark:bg-[#444444] shadow-xl rounded-lg mb-[5px] mt-[5px]">
                <div className="flex-1">
                  <div className="font-bold text-dark mb-[7px] dark:text-white">
                    {t("Energy Savings")}
                  </div>
                  <div className="flex items-center gap-[10px]">
                    <div className="text-dark font-bold text-3xl dark:text-white">
                      {stateData.EnergySaving.toFixed(2)}
                      <span className="text-[#737B8B] text-[12px] font-normal leading-[12px] dark:text-white">
                        {" "}
                        {t("Kwh")}
                      </span>
                    </div>
                    <button className="px-4 py-2 twrap bg-[#5DA3E9] text-white text-[16px] ml-16 font-bold rounded-md flex items-center justify-center relative gap-[20px]">
                      {t("Energy Credits")}
                    </button>
                  </div>
                </div>
                <div className="p-[10px] rounded-full">
                  <img src="/assets/icons/power.svg" alt="powericon" />
                </div>
              </div>

              <div className="p-[6px_16px] bg-white dark:bg-[#444444] rounded-lg w-full shadow-xl mt-0">
                <div className="flex flex-col justify-between items-start">
                  <div className="flex items-center justify-between w-full">
                    <div className="font-bold text-dark mb-[7px] dark:text-white">
                      {t("Number of jobs done")}
                    </div>
                    <Popover>
                      <PopoverHandler>
                        <div>
                          <div className="p-[7px] rounded-full">
                            <img
                              src="/assets/icons/graph.svg"
                              alt="graph"
                              className="cursor-pointer"
                              onClick={() =>
                                setgraphSelection("No_of_Jobs_done")
                              }
                            />
                          </div>
                        </div>
                      </PopoverHandler>
                      <PopoverContent className="z-30 w-[350px] dark:bg-[#444444] dark:border-[#68DCB5]">
                        <ActiveVehicleChart
                          graphSelection={graphSelection}
                          isDarkTheme={isDarkTheme}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="text-dark font-bold text-3xl dark:text-white">
                      {stateData.NoofJobsDone}
                    </div>
                    <div className="flex items-center">
                      <div className="text-secondary ml-2 text-xs"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-6">
          <div className="rounded-lg w-2/3 bg-white p-6 dark:bg-box-pattern2">
            {/* <h2 className='text-xl text-dark mb-2'>Consumption profile</h2> */}
            <div className="flex gap-3 items-center w-full">
              <div className="w-full relative dark:bg-[#444444]">
                <div className="absolute z-[1] mt-[14px] w-full px-[10px]">
                  <div className="grid justify-center grid-cols-2 gap-3 w-full">
                    <div className="flex justify-start">
                      <PieChart stateData={stateData} />
                    </div>
                    <div className="flex justify-end">
                      <PieChartForecasted stateData={stateData} />
                    </div>
                    {/* <div className='flex-1 mb-2 w-full'>
                    <div className='rounded-full overflow-hidden'>
                      <span className='mx-2'>{stateData.Future_Red_percent}%</span>
                      <span className='mx-2'>{stateData.Future_Green_percent}%</span>
                      <span className='mx-2'>{stateData.Future_Yellow_percent}%</span>
                    </div>
                    <Line percent={multiPercentage} strokeWidth={2} trailWidth={2} strokeColor={multiPercentageStrokeColors} />
                  </div>
                  <div className='flex-1 mb-2 w-full'>
                    <div className='rounded-full overflow-hidden'>
                      <span className='mx-2'>{stateData.Future_Red_percent}%</span>
                      <span className='mx-2'>{stateData.Future_Green_percent}%</span>
                      <span className='mx-2'>{stateData.Future_Yellow_percent}%</span>
                    </div>
                    <Line percent={multiPercentage} strokeWidth={2} trailWidth={2} strokeColor={multiPercentageStrokeColors} />
                  </div> */}
                  </div>
                  <div className="grid grid-cols-2  gap-2 mb-[4px]">
                    <button
                      onClick={() => setTab(1)}
                      className={`bg-white bg-opacity-70 ml-[55px] text-[12px] rounded py-1.5  text-gray-700 font-medium px-[7px] w-fit focus-visible:outline-none`}
                    >
                      {t("Present")}
                    </button>
                    <button
                      onClick={() => setTab(2)}
                      className={`bg-white bg-opacity-70 ml-auto mr-[45px] text-[12px]  rounded  py-1.5 text-gray-700 font-medium px-[7px] w-fit`}
                    >
                      {t("Forecasted")}
                    </button>
                  </div>
                </div>

                <div onClick={() => setModal(true)}>
                  <Wrapper apiKey={mapKey} libraries={["places"]}>
                    <FleetViewMap modal={modal} />
                  </Wrapper>
                </div>
                <Transition.Root show={modal} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-[100]"
                    onClose={setModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-[8px] bg-white text-left shadow-xl transition-all sm:my-8 max-w-7xl w-full">
                            <div>
                              <Wrapper apiKey={mapKey} libraries={["places"]}>
                                <FleetViewMap />
                              </Wrapper>
                              <div className="grid justify-center items-start grid-cols-2 gap-3 w-full absolute top-[5%] left-0 right-0 bottom-0 max-h-[138px]">
                                <div className="flex justify-center items-center flex-col w-[150px]">
                                  <PieChart stateData={stateData} />
                                  <button
                                    onClick={() => setTab(1)}
                                    className={`bg-white bg-opacity-70 text-[12px] rounded py-1.5  text-gray-700 font-medium px-[7px] w-fit focus-visible:outline-none`}
                                  >
                                    {t("Present")}
                                  </button>
                                </div>
                                <div className="flex justify-center items-center flex-col w-[150px] ml-auto">
                                  <PieChartForecasted stateData={stateData} />
                                  <button
                                    onClick={() => setTab(2)}
                                    className={`bg-white bg-opacity-70 text-[12px]  rounded  py-1.5 text-gray-700 font-medium px-[7px] w-fit`}
                                  >
                                    {t("Forecasted")}
                                  </button>
                                </div>
                              </div>
                              {/* <button onClick={()=>setModal(false)} className='text-[#5DA3E9] text-[28px] absolute right-[10px] top-[24px] border-0 outline-none'><IoCloseCircleOutline /></button> */}
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </div>
              <div className="w-full relative dark:bg-[#444444] rounded">
                <div className="absolute text-xl twrap text-dark translate-x-[-50%] left-1/2 -top-1 dark:text-white">
                  {t("Efficiency Opportunity")}
                </div>
                <div className="relative max-w-[300px] mx-auto max-h-[300px] mb-1">
                  {/* <button className='absolute top-[52%] translate-x-[50%] bg-[#5DA3E9] px-2.5 py-1.5 rounded-full text-white -translate-y-[50%] right-[45%]' onClick={() => setOpen(!open)}><TbHandClick /></button> */}
                  {/* <HexagonChart /> */}
                  {/* <img src="/assets/icons/pointer-hand.svg" onClick={() => setOpen(!open)} className='absolute top-[50%] w-6 h-6 translate-x-[-50%] left-[50%] translate-y-[-50%] cursor-pointer' alt="pointer-hand" /> */}
                  <SpiderChart
                    stateData={stateData}
                    isDarkTheme={isDarkTheme}
                  />
                </div>
                <div className="grid grid-cols-2  mx-auto gap-2">
                  <button
                    onClick={() => setTab(1)}
                    className={`py-3 text-gray-700 font-medium dark:text-white`}
                  >
                    {t("Present")}
                  </button>
                  <button
                    onClick={() => setTab(2)}
                    className={`py-3 text-gray-700 font-medium dark:text-white`}
                  >
                    {t("Forecasted")}
                  </button>
                </div>
              </div>
            </div>
            {/* <div className='flex items-center gap-4'>
              <div className='bg-white rounded-lg w-1/3 p-4'>
                <div className='flex flex-col justify-between items-start'>
                  <div className='flex mb-6'>
                    <img src="/assets/icons/fuel-consumption.svg" alt="fuel-consumption" className='mr-2' />
                    <div className='text-dark1'>
                      <h3 className='font-bold'>Fuel</h3>
                      <h4>consumption</h4>
                    </div>
                  </div>
                  <div className='mb-6'>
                    <div className='text-dark font-medium text-2xl mb-2.5 flex items-center'>{stateData.FuelConsumed} <sub className='text-secondary text-xs'> Gallons</sub></div>
                    <div className='flex items-center'>
                      <div className='flex items-center'>
                      <span className='text-sm font-bold'>{stateData.fuelPerMile?.toFixed(2)} gal/mile</span>
                        <Icon path={stateData.change_FuelConsumed <= 0 ? mdiArrowDownThin : mdiArrowUpThin} size={1} className={`${stateData.change_FuelConsumed < 0 ? "text-[#0DBF4F]" : 'text-[#E4232F]'}`} />
                        <span className={`${stateData.change_FuelConsumed < 0 ? "text-[#0DBF4F]" : 'text-[#E4232F]'} text-sm font-bold`}>{stateData.change_FuelConsumed.toFixed(2)} %</span>
                      </div>
                      <div className='text-secondary ml-2 text-xs'></div>
                    </div>
                  </div>
                  <Popover placement='bottom-start'>
                    <PopoverHandler>
                      <div>
                        <img src="/assets/icons/graph.svg" alt="graph" className='cursor-pointer' onClick={() => setgraphSelection("Fuel Consumed")} />
                      </div>
                    </PopoverHandler>
                    <PopoverContent className='z-30 w-[350px]'>
                      <ActiveVehicleChart graphSelection={graphSelection} />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div className='bg-white rounded-lg w-1/3 p-4'>
                <div className='flex flex-col justify-between items-start'>
                  <div className='flex mb-6'>
                    <img src="/assets/icons/enery.svg" alt="energy-used" className='mr-2' />
                    <div className='text-dark1'>
                      <h3 className='font-bold'>Energy</h3>
                      <h4>used</h4>
                    </div>
                  </div>
                  <div className='mb-6'>
                    <div className='text-dark font-medium text-2xl mb-2.5 flex items-center'>{stateData.Energy_KJ.toFixed(2)} <sub className='text-secondary text-xs'> Mega Joule</sub>
                    </div>
                    <div className='flex items-center'>
                      <div className='flex items-center'>
                      <span className='text-sm font-bold'>{stateData.energyPerMile?.toFixed(2)} KJ/mile</span>
                        <Icon path={stateData.change_EnergyKJ <= 0 ? mdiArrowDownThin : mdiArrowUpThin} size={1} className={`${stateData.change_EnergyKJ < 0 ? "text-[#0DBF4F]" : 'text-[#E4232F]'}`} />
                        <span className={`${stateData.change_EnergyKJ < 0 ? "text-[#0DBF4F]" : 'text-[#E4232F]'} text-sm font-bold`}>{stateData.change_EnergyKJ.toFixed(2)} %</span>
                      </div>
                      <div className='text-secondary ml-2 text-xs'></div>
                    </div>
                  </div>
                  <Popover placement='top'>
                    <PopoverHandler>
                      <div>
                        <img src="/assets/icons/graph.svg" alt="graph" className='cursor-pointer' onClick={() => setgraphSelection("Energy(KJ)")} />
                      </div>
                    </PopoverHandler>
                    <PopoverContent className='z-30 w-[350px]'>
                      <ActiveVehicleChart graphSelection={graphSelection} />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div className='bg-white rounded-lg w-1/3 p-4'>
                <div className='flex flex-col justify-between items-start'>
                  <div className='flex mb-6'>
                    <img src="/assets/icons/co2.svg" alt="emission-generated" className='mr-2' />
                    <div className='text-dark1'>
                      <h3 className='font-bold'>Emission</h3>
                      <h4>generated</h4>
                    </div>
                  </div>
                  <div className='mb-6'>
                    <div className='text-dark font-medium text-2xl mb-2.5'>{stateData.CO2_Emission} <sub className='text-secondary text-xs'> kg</sub></div>
                    <div className='flex items-center'>
                      <div className='flex items-center'>
                        <span className='text-sm font-bold'>{stateData.emissionPerMile} Kg/mile</span>
                        <Icon path={stateData.change_CO2EmissionKg <= 0 ? mdiArrowDownThin : mdiArrowUpThin} size={1} className={`${stateData.change_CO2EmissionKg < 0 ? "text-[#0DBF4F]" : 'text-[#E4232F]'}`} />
                        <span className={`${stateData.change_CO2EmissionKg < 0 ? "text-[#0DBF4F]" : 'text-[#E4232F]'} text-sm font-bold`}>{stateData.change_CO2EmissionKg.toFixed(2)} %</span>
                      </div>
                      <div className='text-secondary ml-2 text-xs'></div>
                    </div>
                  </div>
                  <Popover placement='top'>
                    <PopoverHandler>
                      <div>
                        <img src="/assets/icons/graph.svg" alt="graph" className='cursor-pointer' onClick={() => setgraphSelection("CO2 Emission (Kg)")} />
                      </div>
                    </PopoverHandler>
                    <PopoverContent className='z-30 w-[350px]'>
                      <ActiveVehicleChart graphSelection={graphSelection} />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            </div> */}
          </div>
          <div className="rounded-lg w-1/3 bg-white p-6 dark:bg-box-pattern2 relative">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl text-dark dark:text-white">
                {t("Safety & predictive maintenance")}
              </h2>
              {/* <div className="absolute top-2 right-2 ">
                <select
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                  className="mr-4 dark:text-white dark:bg-[#202020]"
                >
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Portuguese">Portuguese</option>
                  <option value="Chinese">Chinese</option>
                </select>
              </div> */}
              <div>
                {isSpeaking ? (
                  <Icon
                    path={mdiStopCircleOutline}
                    size={"25px"}
                    className="dark:text-white cursor-pointer"
                    onClick={stopAudio}
                  />
                ) : (
                  <Icon
                    path={mdiVolumeHigh}
                    size={"25px"}
                    className="dark:text-white cursor-pointer"
                    onClick={() =>
                      speak(
                        stateData.LowTirePressureVehicle,
                        stateData.LowBatteryVoltageVehicle
                      )
                    }
                  />
                )}
              </div>
            </div>

            <div className="flex items-center flex-col">
              <div className="flex w-full my-4 dark:bg-[#E4232F] rounded-md p-2">
                <div className="mr-2">
                  <img
                    src="/assets/icons/Vehiclesrunning2.svg"
                    alt="Vehiclesrunning"
                  />
                </div>
                <div className="flex-grow">
                  <div className="text-dark font-bold text-2xl dark:text-white">
                    {stateData.LowTirePressureVehicle}
                  </div>
                  <h6
                    className={`${
                      stateData.LowTirePressureVehicle <= 0
                        ? "text-[#27403F]"
                        : "text-[#E4232F]"
                    } ${
                      i18n.language === "en" ? "text-[18px]" : "text-[16px]"
                    }  font-normal dark:text-white`}
                  >
                    {t("Vehicles will need air today")}
                    {/* <span className={`${stateData.LowTirePressureVehicle <= 0 ? 'text-[#27403F]' : "text-[#E4232F]"} text-[18px] font-bold`}>low tire pressure</span> */}
                  </h6>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <Icon
                          path={
                            stateData.change_LowTirePressureVehicle < 0
                              ? mdiArrowDownThin
                              : mdiArrowUpThin
                          }
                          size={1}
                          className={`${
                            stateData.change_LowTirePressureVehicle < 0
                              ? "text-[#0DBF4F]"
                              : "text-[#E4232F]"
                          } dark:text-white`}
                        />
                        <span
                          className={`${
                            stateData.change_LowTirePressureVehicle < 0
                              ? "text-[#0DBF4F]"
                              : "text-[#E4232F]"
                          } text-sm font-bold dark:text-white`}
                        >
                          {stateData.change_LowTirePressureVehicle} %
                        </span>
                      </div>
                      <div className="text-secondary ml-2 text-xs"></div>
                    </div>
                    {/* <PopupCustom /> */}
                    <button
                      className="max-w-[195px] twrap p-1.5 bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative"
                      onClick={() => setOpenNotify(true)}
                    >
                      {t("Notify garages")}{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex my-4 w-full dark:bg-[#F37E44] rounded-md p-2">
                <div className="mr-2">
                  <img
                    src="/assets/icons/batteryvoltage2.svg"
                    alt="batteryvoltage"
                  />
                </div>
                <div className="flex-grow">
                  <div className="text-dark font-bold text-2xl dark:text-white">
                    {stateData.LowBatteryVoltageVehicle}
                  </div>
                  <h6
                    className={`${
                      stateData.LowBatteryVoltageVehicle <= 0
                        ? "text-[#27403F]"
                        : "text-[#E4232F]"
                    } ${
                      i18n.language === "en" ? "text-[18px]" : "text-[16px]"
                    }  font-normal dark:text-white`}
                  >
                    {t("Vehicles will need maintanance in 30 days")}
                    {/* <h6 className={`${stateData.Batteries <= 0 ? 'text-[#27403F]' : "text-[#E4232F]"} text-[18px] font-normal`}>Vehicles will need maintanance in 30 days */}
                    {/* <span className={`${stateData.LowTirePressureVehicle <= 0 ? 'text-[#27403F]' : "text-[#E4232F]"} text-[18px] font-bold`}>low battery voltage</span> */}
                  </h6>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <Icon
                          path={
                            stateData.change_LowBatteryVoltageVehicle < 0
                              ? mdiArrowDownThin
                              : mdiArrowUpThin
                          }
                          size={1}
                          className={`${
                            stateData.change_LowBatteryVoltageVehicle < 0
                              ? "text-[#0DBF4F]"
                              : "text-[#E4232F]"
                          } dark:text-white`}
                        />
                        <span
                          className={`${
                            stateData.change_LowBatteryVoltageVehicle < 0
                              ? "text-[#0DBF4F]"
                              : "text-[#E4232F]"
                          } text-sm font-bold dark:text-white`}
                        >
                          {stateData.change_LowBatteryVoltageVehicle} %
                        </span>
                      </div>
                      <div className="text-secondary ml-2 text-xs"></div>
                    </div>
                    {stateData.LowTirePressureVehicle > 0 ? (
                      <button className="max-w-[195px] twrap p-1.5 bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative">
                        {t("Notify garages")}
                      </button>
                    ) : (
                      <button className="max-w-[195px] twrap p-1.5 bg-[#CDCBCB99] text-white text-[16px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px]">
                        {t("Notify garages")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup openTire={openTire} setOpenTire={setOpenTire} />
      
      <EfficiencyModal
        open={open}
        setOpen={() => setOpen(!open)}
        language={selectedLanguage}
      />

      <NotifyModal
        openNotify={openNotify}
        setOpenNotify={setOpenNotify}
        setOpenForm={setOpenForm}
      />
      
      <SubscribeForm openForm={openForm} setOpenForm={setOpenForm} />
    </Layout>
  );
};

export default FleetView;
