import React, { useEffect, useRef } from 'react'

const VehiclesTwinsMap = ({ vinLocation, onFuelEfficiencyChange }) => {
  const ref = useRef(null);
  let map;
  let directionsService;
  let directionsRenderer;
  let markerA;
  let routeDirection;


  const animateMarker = (points, duration) => {
    const numPoints = points.length;
    let startTime = new Date().getTime();

    const moveMarker = () => {
      const elapsedTime = new Date().getTime() - startTime;
      const fraction = elapsedTime / duration;
    
      if (fraction >= 1) {
        markerA.setPosition(vinLocation?.startLatLng); 
        routeDirection.setPath(points); 
        // map.setCenter(new window.google.maps.LatLng(40.273502, -86.126976));
        // map.setZoom(8);
        return;
      }
    
      const currentIndex = Math.floor(fraction * (numPoints - 1));
      const nextIndex = currentIndex + 1;
      const currentPoint = points[currentIndex];
      const nextPoint = points[nextIndex];
    
      const newPosition = window.google.maps.geometry.spherical.interpolate(
        currentPoint,
        nextPoint,
        fraction * (numPoints - 1) - currentIndex
      );
    
      markerA.setPosition(newPosition);
      // map.setCenter(newPosition);
    
      const remainingRoute = points.slice(nextIndex);
      if (routeDirection) {
        routeDirection.setPath(remainingRoute);
      } else {
        routeDirection = new window.google.maps.Polyline({
          path: remainingRoute,
          strokeColor: "#2A2E35",
          strokeWeight: 5,
          // strokeOpacity: 0.7,
          map: map
        });
      }
    
      setTimeout(moveMarker, 15);
    };
    

    moveMarker();
  };

  const calculateAndDisplayRouteForRealTime = () => {
    const vinTripLocation = vinLocation;
    let fuelEfficiency = 0;
    if (vinTripLocation?.fuelEfficiency) {
      fuelEfficiency = vinTripLocation?.fuelEfficiency.toFixed(2);
      onFuelEfficiencyChange(fuelEfficiency);
    }
    if (
      vinLocation?.startLatLng?.lat &&
      vinLocation?.startLatLng?.lng &&
      vinLocation?.endLatLng?.lat &&
      vinLocation?.endLatLng?.lng
    ) {
      const startLatLng = new window.google.maps.LatLng(
        vinLocation?.startLatLng?.lat,
        vinLocation?.startLatLng?.lng
      );
      const endLatLng = new window.google.maps.LatLng(
        vinLocation?.endLatLng?.lat,
        vinLocation?.endLatLng?.lng
      );
  
      let markerIcon = '';
      const markerSize = new window.google.maps.Size(25, 25); 
      if (vinLocation.category === 'High') {
        markerIcon = "/assets/icons/markerGreen.png";
      } else if (vinLocation.category === 'Medium') {
        markerIcon = "/assets/icons/markerYellow.png";
      } else if (vinLocation.category === 'Low') {
        markerIcon = "/assets/icons/markerRed.png";
      }
  
      if (!markerA) {
        markerA = new window.google.maps.Marker({
          map,
          position: startLatLng,
          title: "Starting Point",
          icon: {
            url: markerIcon,
            scaledSize: markerSize,
          },
        });
      }
  
      const markerB = new window.google.maps.Marker({
        map,
        position: endLatLng,
        title: "Fuel Efficiency: " + fuelEfficiency + " %",
        icon: {
          url: markerIcon,
          scaledSize: markerSize,
        },
      });
  
      directionsService
        .route({
          origin: startLatLng,
          destination: endLatLng,
          travelMode: "DRIVING",
        })
        .then((response) => {
          const route = response.routes[0].overview_path;
          // directionsRenderer.setDirections(response);
          // directionsRenderer.setOptions({
          //   suppressMarkers: true,
          //   polylineOptions: {
          //     strokeColor: "#2A2E35",
          //     strokeWeight: 5,
          //     strokeOpacity: 0.7,
          //   },
          // });
          animateMarker(route, 180000);
        })
        .catch((e) => {
          console.log("Directions request failed due to " + e);
          directionsRenderer.setMap(null);
        });
    } else {
      directionsRenderer.setMap(null);
      if (map) {
        map.setCenter(new window.google.maps.LatLng(40.273502, -86.126976));
      }
    }
  };

  useEffect(() => {
    const initializeMap = () => {
      if (!map) {
        const centerLatLng = vinLocation
          ? new window.google.maps.LatLng(
              (vinLocation.startLatLng.lat + vinLocation.endLatLng.lat) / 2,
              (vinLocation.startLatLng.lng + vinLocation.endLatLng.lng) / 2
            )
          : new window.google.maps.LatLng(40.273502, -86.126976);
  
        map = new window.google.maps.Map(ref.current, {
          center: centerLatLng,
          zoom: 8,
          // mapTypeId: window.google.maps.MapTypeId.HYBRID,
        });
        directionsService = new window.google.maps.DirectionsService();
        directionsRenderer = new window.google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
      }

      if (vinLocation && directionsService && directionsRenderer) {
        calculateAndDisplayRouteForRealTime();
      } else {
        directionsRenderer.setMap(null);
      }
    };
    initializeMap();
  }, [vinLocation])

  return (
    <div
      ref={ref}
      id="map"
      style={{ borderRadius: "8px", width: "100%", height: "100%" }}
    />
  )
}

export default VehiclesTwinsMap