import React, { useEffect, useState } from "react";
import Header from "../component/common/Header/Header";
import Layout from "../component/common/Layout/Layout";
import {
  mdiArrowDownThin,
  mdiArrowUpThin,
  mdiStopCircleOutline,
  mdiVolumeHigh,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  getFleetOverviewData,
  getTiresAvg,
  getTiresStatus,
  getTiresStatusFlag,
  getTiresStatusNotifications,
  getTripLocation,
  getVehicleInfo,
  getWeatherData,
} from "../redux/action/action";
import { useDispatch } from "react-redux";
import { Wrapper } from "@googlemaps/react-wrapper";
import VehiclesTwinsMap from "./VehiclesTwinsMap";
import Modal from "../component/common/Modal";
import VehiclesTwinGasStaion from "./VehiclesTwinGasStaion";
import Popup from "../component/common/popup";
import { UseContextData } from "../context/dateContext";
import { Progressbar } from "./Progressbar";
import baseUrl from "../util/baseUrl";
import axios from "axios";
import { useTranslation } from "react-i18next";
const VehiclesTwins = () => {
  const [open, setOpen] = useState(false);
  const mapKey = process.env.REACT_APP_VITE_MAP_KEY;
  const { date, selectedVin, selectedLanguage } = UseContextData();
  let vinDataIntervalId = null;
  const dispatch = useDispatch();
  const [fuelEfficiency, setFuelEfficiency] = useState(0);
  const [mapvalu, setmapvalu] = useState(true);
  const [openTire, setOpenTire] = useState(false);
  const [distance, setDistance] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioObject, setAudioObject] = useState();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (selectedVin && date && date !== "Invalid Date") {
      getTireStatusService();
      getTiresAvgService();
      getLocationService();
      vinRealTimeDataService();
      FleetOverviewData();
      vinDataIntervalId = setInterval(() => {
        // getLocationService();
        getTireStatusService();
        getTiresAvgService();
        vinRealTimeDataService();
        FleetOverviewData();
      }, 30000);
    }
    return () => {
      // if (selectedVin) {
      if (vinDataIntervalId) {
        clearInterval(vinDataIntervalId);
      }
      // }
    };
  }, [selectedVin, date]);

  const [vinLocation, setVinLocation] = useState({
    startLatLng: {
      lat: 0,
      lng: 0,
    },
    endLatLng: {
      lat: 0,
      lng: 0,
    },
    category: "DefaultCategory",
    fuelEfficiency: "",
  });

  const [weatherData, setWeatherData] = useState({
    temperature: 0,
    humidity: 0,
    windSpeed: 0,
    windDirection: 0,
    rain: 0,
    snowfall: 0,
  });

  const [vinRealTimeData, setVinRealTimeData] = useState({
    liveSpeed: 0,
    distanceTravelled: 0,
    fuelConsumed: 0,
    idleTime: 0,
    totalDrivingTime: [0, 0],
    location: "",
    mileage: 0,
    voltage: 0,
    engineTemp: 0,
    vehicleType: "",
    remainingFuel: 0,
    fuelEfficiency: 0,
    low_battery_voltage: 0,
    low_tire_pressure: 0,
    speed: 0,
    fl: 0,
    fr: 0,
    rr: 0,
    rl: 0,
    fleet_Speeding_saving_data: 0,
    fleet_Idling_Saving_data: 0,
    fleet_Tire_Pressure_Saving_data: 0,
  });

  const Hours = Math.floor(vinRealTimeData.idleTime / 60);
  const minutes = vinRealTimeData.idleTime % 60;

  const [stateData, setStateData] = useState({
    Efficiency: 0,
    change_Efficiency: 0,
  });

  const [tirePressures, setTirePressures] = useState({
    average_pressure_tmps1la: 0,
    average_pressure_tmps1ra: 0,
    average_pressure_tmps2lb: 0,
    average_pressure_tmps2rb: 0,
  });

  const [tireStatus, setTireStatus] = useState({
    tfl: {
      current: 0,
      thirtyMin: 0,
      currentflag: 5,
      forecastedFlag: 5,
      refill: 0,
    },
    tfr: {
      current: 0,
      thirtyMin: 0,
      currentflag: 5,
      forecastedFlag: 5,
      refill: 0,
    },
    trl: {
      current: 0,
      thirtyMin: 0,
      currentflag: 5,
      forecastedFlag: 5,
      refill: 0,
    },
    trr: {
      current: 0,
      thirtyMin: 0,
      currentflag: 5,
      forecastedFlag: 5,
      refill: 0,
    },
  });

  const vinRealTimeDataService = async () => {
    const requestParams = { vin: selectedVin };

    try {
      const res = await dispatch(getVehicleInfo(requestParams));
      if (res && res?.payload) {
        const responseData = res?.payload?.data;
        setVinRealTimeData({
          ...vinRealTimeData,
          liveSpeed: +responseData?.live_speed?.toFixed(2) || 0,
          distanceTravelled: +responseData?.travelled?.toFixed(2) || 0,
          fuelConsumed: +responseData?.fuel_consumed?.toFixed(2) || 0,
          idleTime: +responseData?.idletime || 0,
          totalDrivingTime:
            responseData?.total_driving_time &&
            responseData?.total_driving_time?.length > 0
              ? responseData?.total_driving_time
              : [0, 0],
          location: responseData?.state || "",
          mileage: +responseData?.current_mileage?.toFixed(1) || 0,
          voltage: responseData?.battery_voltage?.toFixed(1) || 0,
          vehicleType: responseData?.vehicle_type || "",
          remainingFuel: +responseData?.remaining_fuel || 0,
          fuelEfficiency: +responseData?.fuel_efficiency?.toFixed(2) || 0,
          low_battery_voltage: +responseData?.low_battery_voltage || 0,
          low_tire_pressure: +responseData?.low_tire_pressure || 0,
          speed: +responseData?.speed || 0,
          fl: +responseData?.FL.toFixed(2) || 0,
          fr: +responseData?.FR.toFixed(2) || 0,
          rr: +responseData?.RR.toFixed(2) || 0,
          rl: +responseData?.RL.toFixed(2) || 0,
          fleet_Speeding_saving_data:
            +responseData?.fleet_Speeding_saving_data.toFixed(2) || 0,
          fleet_Idling_Saving_data:
            +responseData?.fleet_Idling_Saving_data.toFixed(2) || 0,
          fleet_Tire_Pressure_Saving_data:
            +responseData?.fleet_Tire_Pressure_Saving_data.toFixed(2) || 0,
        });
      } else {
        setVinRealTimeData({
          liveSpeed: 0,
          distanceTravelled: 0,
          fuelConsumed: 0,
          idleTime: 0,
          totalDrivingTime: [0, 0],
          location: "",
          mileage: 0,
          voltage: 0,
          engineTemp: 0,
          vehicleType: "",
          remainingFuel: 0,
          fuelEfficiency: 0,
          speed: 0,
          fl: 0,
          fr: 0,
          rr: 0,
          rl: 0,
          fleet_Speeding_saving_data: 0,
          fleet_Idling_Saving_data: 0,
          fleet_Tire_Pressure_Saving_data: 0,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWeatherInfo = async (latitude, longitude) => {
    const requestParams = {
      latitude: latitude,
      longitude: longitude,
      date: date,
    };
    try {
      const response = await dispatch(getWeatherData(requestParams));
      const responseData = response?.payload?.data;
      if (responseData) {
        setWeatherData({
          ...weatherData,
          temperature: responseData.hourly.temperature_2m[0] || 0,
          humidity: responseData.hourly.relativehumidity_2m[0] || 0,
          windSpeed: responseData.hourly.windspeed_10m[0] || 0,
          windDirection: responseData.hourly.windspeed_10m[0] || 0,
          rain: responseData.hourly.rain[0] || 0,
          snowfall: responseData.hourly.snowfall[0] || 0,
        });
      } else {
        setWeatherData({
          temperature: 0,
          humidity: 0,
          windSpeed: 0,
          windDirection: 0,
          rain: 0,
          snowfall: 0,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocationService = async () => {
    const requestParams = { vin: selectedVin, date: date };
    try {
      const res = await dispatch(getTripLocation(requestParams));
      if (res && res?.payload && res?.payload?.data) {
        const responseData = res?.payload?.data;
        if (
          responseData.latitude &&
          responseData.latitude.length > 0 &&
          responseData.longitude &&
          responseData.longitude.length > 0 &&
          responseData.category &&
          responseData.category.length > 0
        ) {
          setVinLocation({
            ...vinLocation,
            startLatLng: {
              lat: responseData.latitude[0],
              lng: responseData.longitude[0],
            },
            endLatLng: {
              lat: responseData.latitude[responseData.latitude.length - 1],
              lng: responseData.longitude[responseData.longitude.length - 1],
            },
            category: responseData.category[0],
            fuelEfficiency: responseData.fuel_efficiency[0],
          });
          getWeatherInfo(responseData.latitude[0], responseData.longitude[0]);
        } else {
          setVinLocation({
            ...vinLocation,
            startLatLng: {
              lat: 0,
              lng: 0,
            },
            endLatLng: {
              lat: 0,
              lng: 0,
            },
            category: "DefaultCategory",
            fuelEfficiency: "",
          });
        }
      } else {
        setVinLocation({
          ...vinLocation,
          startLatLng: {
            lat: 0,
            lng: 0,
          },
          endLatLng: {
            lat: 0,
            lng: 0,
          },
          category: "DefaultCategory",
          fuelEfficiency: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FleetOverviewData = async () => {
    try {
      const response = await dispatch(getFleetOverviewData());
      const responseData = response?.payload?.data;
      if (responseData) {
        setStateData({
          ...stateData,
          Efficiency: +responseData["Efficiency"].toFixed(2) || 0,
          change_Efficiency:
            +responseData["percenatge_change_Efficiency"].toFixed(2) || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFuelEfficiencyChange = (newFuelEfficiency) => {
    setFuelEfficiency(newFuelEfficiency);
  };

  const onDistanceChange = (distance) => {
    setDistance(distance);
  };

  const resetTiresStatus = () => {
    setTireStatus({
      tfl: {
        current: 0,
        thirtyMin: 0,
        currentflag: 5,
        forecastedFlag: 5,
        refill: 0,
      },
      tfr: {
        current: 0,
        thirtyMin: 0,
        currentflag: 5,
        forecastedFlag: 5,
        refill: 0,
      },
      trl: {
        current: 0,
        thirtyMin: 0,
        currentflag: 5,
        forecastedFlag: 5,
        refill: 0,
      },
      trr: {
        current: 0,
        thirtyMin: 0,
        currentflag: 5,
        forecastedFlag: 5,
        refill: 0,
      },
    });
  };

  const getTireStatusService = async () => {
    const requestParams = { vin: selectedVin };
    try {
      const response = await dispatch(getTiresStatus(requestParams));
      const responseData = response?.payload?.data;
      if (responseData) {
        const tfl = responseData.find((item) => item.position === "tmps1la");
        const tfr = responseData.find((item) => item.position === "tmps1ra");
        const trl = responseData.find((item) => item.position === "tmps2lb");
        const trr = responseData.find((item) => item.position === "tmps2rb");
        let tireStatusCopy = { ...tireStatus };

        tireStatusCopy = {
          ...tireStatus,
          tfl: {
            ...tfl,
            current: +tfl.current.toFixed(2),
            thirtyMin: +tfl.forecast.toFixed(2),
          },
          tfr: {
            ...tfr,
            current: tfr.current.toFixed(2),
            thirtyMin: tfr.forecast.toFixed(2),
          },
          trl: {
            ...trl,
            current: +trl.current.toFixed(2),
            thirtyMin: +trl.forecast.toFixed(2),
          },
          trr: {
            ...trr,
            current: +trr.current.toFixed(2),
            thirtyMin: +trr.forecast.toFixed(2),
          },
        };
        getTiresStatusFlagService(tireStatusCopy);
      } else {
        resetTiresStatus();
      }
    } catch (error) {
      console.log("error", error);
      resetTiresStatus();
    }
  };

  const getTiresStatusFlagService = async (tireStatusCopy) => {
    const requestParams = { vin: selectedVin };
    try {
      const response = await dispatch(getTiresStatusFlag(requestParams));
      const responseData = response?.payload?.data;
      if (responseData) {
        const tflFlag = responseData.find(
          (item) => item.position === "tmps1la"
        );
        const tfrFlag = responseData.find(
          (item) => item.position === "tmps1ra"
        );
        const trlFlag = responseData.find(
          (item) => item.position === "tmps2lb"
        );
        const trrFlag = responseData.find(
          (item) => item.position === "tmps2rb"
        );
        tireStatusCopy = {
          ...tireStatusCopy,
          tfl: { ...tireStatusCopy.tfl, currentflag: tflFlag.flag },
          tfr: { ...tireStatusCopy.tfr, currentflag: tfrFlag.flag },
          trl: { ...tireStatusCopy.trl, currentflag: trlFlag.flag },
          trr: { ...tireStatusCopy.trr, currentflag: trrFlag.flag },
        };
        getTiresStatusNotificationService(tireStatusCopy);
      } else {
        resetTiresStatus();
      }
    } catch (error) {
      console.log("error", error);
      resetTiresStatus();
    }
  };

  const getTiresStatusNotificationService = async (tireStatusCopy) => {
    const requestParams = { vin: selectedVin };
    try {
      const response = await dispatch(
        getTiresStatusNotifications(requestParams)
      );
      const responseData = response?.payload?.data;
      if (responseData) {
        const tflFlag = responseData.find(
          (item) => item.position === "tmps1la"
        );
        const tfrFlag = responseData.find(
          (item) => item.position === "tmps1ra"
        );
        const trlFlag = responseData.find(
          (item) => item.position === "tmps2lb"
        );
        const trrFlag = responseData.find(
          (item) => item.position === "tmps2rb"
        );
        tireStatusCopy = {
          ...tireStatusCopy,
          tfl: {
            ...tireStatusCopy.tfl,
            forecastedFlag: tflFlag.flag,
            refill: tflFlag.refill.toFixed(2) || 1,
          },
          tfr: {
            ...tireStatusCopy.tfr,
            forecastedFlag: tfrFlag.flag,
            refill: tfrFlag.refill.toFixed(2) || 1,
          },
          trl: {
            ...tireStatusCopy.trl,
            forecastedFlag: trlFlag.flag,
            refill: trlFlag.refill.toFixed(2) || 1,
          },
          trr: {
            ...tireStatusCopy.trr,
            forecastedFlag: trrFlag.flag,
            refill: trrFlag.refill.toFixed(2) || 1,
          },
        };
        setTireStatus(tireStatusCopy);
      } else {
        resetTiresStatus();
      }
    } catch (error) {
      console.log("error", error);
      resetTiresStatus();
    }
  };

  const getTiresAvgService = async () => {
    const requestParams = { vin: selectedVin };
    try {
      const response = await dispatch(getTiresAvg(requestParams));
      const responseData = response?.payload?.data;
      if (responseData) {
        setTirePressures({
          average_pressure_tmps1la:
            responseData.average_pressure_tmps1la.toFixed(2),
          average_pressure_tmps1ra:
            responseData.average_pressure_tmps1ra.toFixed(2),
          average_pressure_tmps2lb:
            responseData.average_pressure_tmps2lb.toFixed(2),
          average_pressure_tmps2rb:
            responseData.average_pressure_tmps2rb.toFixed(2),
        });
      } else {
        setTirePressures({
          average_pressure_tmps1la: 0,
          average_pressure_tmps1ra: 0,
          average_pressure_tmps2lb: 0,
          average_pressure_tmps2rb: 0,
        });
      }
    } catch (error) {
      console.log("error", error);
      setTirePressures({
        average_pressure_tmps1la: 0,
        average_pressure_tmps1ra: 0,
        average_pressure_tmps2lb: 0,
        average_pressure_tmps2rb: 0,
      });
    }
  };

  // const [selectedLanguage, setSelectedLanguage] = useState("English");
  // const [selectedLanguageImroveNow, setSelectedLanguageImroveNow] = useState("English");

  const speak = async () => {
    if (isSpeaking) {
      return;
    } else {
      setIsSpeaking(true);
      try {
        const text = `The vehicle is running on low battery voltage`;
        const response = await axios.get(
          `${baseUrl}/text_to_speech?text=${encodeURIComponent(
            text
          )}&language=${selectedLanguage}`,
          {
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], { type: "audio/mp3" });
        const blobUrl = URL.createObjectURL(blob);
        const audio = new Audio(blobUrl);
        audio.onended = () => {
          setIsSpeaking(false);
        };
        audio.play();
        setAudioObject(audio);
      } catch (err) {
        console.log("Error converting text to speech", err);
      }
    }
  };
  const stopAudio = () => {
    if (audioObject) {
      audioObject.pause();
      audioObject.currentTime = 0;
      setIsSpeaking(false);
      setAudioObject();
    }
  };

  // const [showMenu, setShowMenu] = useState(false);
  // const handleBrandMenuClick = () => {
  //   setShowMenu(!showMenu);
  // };

  // const handleLanguageSelect = (language) => {
  //   setSelectedLanguageImroveNow(language);
  //   setShowMenu(false);
  // };

  const vehicleType = vinRealTimeData?.vehicleType
    ? vinRealTimeData?.vehicleType.charAt(0).toUpperCase() +
      vinRealTimeData?.vehicleType.slice(1).toLowerCase()
    : "";

  const location = vinRealTimeData?.location;

  return (
    <Layout>
      <div className="h-full">
        <Header selectedMenu={(selectedOption) => selectedOption} />
        <div>
          <h2 className="text-[#27403F] dark:text-white text-[24px] font-normal mt-[26px]">
            <span className="font-bold">{t("AC6789")}</span>{" "}
            {t("Real time status")}
          </h2>
          <div className="shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)] bg-white rounded-[8px] px-[39px] pt-[32px] pb-[11px] flex gap-[36px] dark:bg-box-pattern2">
            <div className="min-w-[205px]">
              <div className="text-center dark:text-white">
                {t("Fuel efficiency")}
              </div>
              <div className="mx-auto">
                <Progressbar value={stateData.Efficiency} />
              </div>
              <div className="flex items-center justify-center mb-6">
                <div className="flex items-center">
                  <Icon
                    path={
                      stateData.change_Efficiency < 0
                        ? mdiArrowDownThin
                        : mdiArrowUpThin
                    }
                    size={1}
                    className={`${
                      stateData.change_Efficiency < 0
                        ? "text-[#F2383A]"
                        : "text-[#0DBF4F]"
                    }`}
                  />
                  <span
                    className={`${
                      stateData.change_Efficiency < 0
                        ? "text-[#F2383A]"
                        : "text-[#0DBF4F]"
                    } text-sm font-bold`}
                  >
                    {stateData.change_Efficiency} %
                  </span>
                </div>
                <div className="text-secondary ml-2 text-xs"></div>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="flex justify-center">
                  <button
                    className="max-w-[168px] px-4 py-2 bg-[#5DA3E9] text-white text-[16px] font-bold w-full rounded-md flex items-center justify-center relative gap-[10px] twrap"
                    onClick={() => setOpenTire(true)}
                  >
                    {t("Improve now")}{" "}
                    <img src="/assets/icons/rightArrow.svg" alt="rightArrow" />
                  </button>
                </div>
                {/* <div className='relative'>
                  <Icon
                    path={mdiVolumeHigh}
                    size={"25px"}
                    className="dark:text-white cursor-pointer"
                    onClick={handleBrandMenuClick}
                  />
                  <div className='absolute bottom-[30px] right-0 '>
                    {showMenu && (
                        <div className="bg-white  rounded-md max-w-[130px] w-full p-2 dark:bg-[#202020] dark:text-white">
                          <p
                            className="cursor-pointer py-1"
                            onClick={() => handleLanguageSelect("English")}
                          >
                            English
                          </p>
                          <p
                            className="cursor-pointer py-1"
                            onClick={() => handleLanguageSelect("Spanish")}
                          >
                            Spanish
                          </p>
                          <p
                            className="cursor-pointer py-1"
                            onClick={() => handleLanguageSelect("Portuguese")}
                          >
                            Portuguese
                          </p>
                          <p
                            className="cursor-pointer py-1"
                            onClick={() => handleLanguageSelect("Chinese")}
                          >
                            Chinese
                          </p>
                        </div>
                      )}
                  </div>
                </div> */}
              </div>
            </div>
            <div className="w-full map-main mb-[18px]">
              <Wrapper apiKey={mapKey} libraries={["places"]}>
                {mapvalu === true ? (
                  <VehiclesTwinsMap
                    vinLocation={vinLocation}
                    onFuelEfficiencyChange={handleFuelEfficiencyChange}
                  />
                ) : (
                  <VehiclesTwinGasStaion
                    vinLocation={vinLocation}
                    onDistanceChange={onDistanceChange}
                  />
                )}
              </Wrapper>
            </div>
            <div className="min-w-[300px] items-center">
              <div className="flex justify-between items-center">
                <div className="flex gap-[11px] items-center">
                  <img
                    src="/assets/images/cloudy-light.svg"
                    alt="cloudy-light"
                  />
                  <h3 className="text-[#27403F] text-[18px] font-bold dark:text-white">
                    {t(`city.${location}`)}
                  </h3>
                </div>
                <h1 className="text-[#27403F] text-[26px] font-bold dark:text-white">
                  {weatherData.temperature}º{" "}
                  <span className="text-[14px] font-normal dark:text-white text-[#787F89]">
                    {t("F")}
                  </span>
                </h1>
              </div>
              <div className="grid grid-cols-2 justify-between gap-[20px] ml-[80px] mt-2">
                <div>
                  <p className="text-[#27403F] text-[8px] font-medium dark:text-white">
                    {t("Wind speed")}
                  </p>
                  <div className="flex gap-2 items-end">
                    <h2 className="text-[#27403F] text-[18px] font-bold leading-[20px] dark:text-white">
                      {weatherData.windSpeed}
                    </h2>
                    <span className="text-[#787F89] text-[8px] dark:text-white">
                      {t("m/h")}
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-[#27403F] text-[8px] font-medium dark:text-white">
                    {t("Rain")}
                  </p>
                  <div className="flex gap-2 items-end">
                    <h2 className="text-[#27403F] text-[18px] font-bold leading-[20px] dark:text-white">
                      {weatherData.rain}
                    </h2>
                    <span className="text-[#787F89] text-[8px] dark:text-white">
                      {t("mm")}
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-[#27403F] text-[8px] font-medium dark:text-white">
                    {t("Humidity")}
                  </p>
                  <div className="flex gap-2 items-end">
                    <h2 className="text-[#27403F] text-[18px] font-bold leading-[20px] dark:text-white">
                      {weatherData.humidity}
                    </h2>
                    <span className="text-[#787F89] text-[8px] dark:text-white">
                      %
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-[#27403F] text-[8px] font-medium dark:text-white">
                    {t("Snowfall")}
                  </p>
                  <div className="flex gap-2 items-end">
                    <h2 className="text-[#27403F] text-[18px] font-bold leading-[20px] dark:text-white">
                      {weatherData.snowfall}
                    </h2>
                    <span className="text-[#787F89] text-[8px] dark:text-white">
                      {t("mm")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)] rounded-[8px] dark:bg-box-pattern2 bg-white p-[8px_16px] mt-[22px]">
                <div className="flex gap-[8px]">
                  {/* <img src="/assets/icons/gas-station1.svg" alt="gas-station" /> */}
                  <img
                    src="/assets/icons/Fuelconsumed.svg"
                    className="w-6"
                    alt="gas-station"
                  />
                  <p
                    className={`text-[#27403F] font-bold   ${
                      i18n.language === "es" || i18n.language === "pt"
                        ? "text-[14px]"
                        : "text-[18px]"
                    }  cursor-pointer hover:text-[#5DA3E9] dark:text-white`}
                    onClick={() => setmapvalu(!mapvalu)}
                  >
                    {t("Closest gas station")}
                  </p>
                </div>
                <div className="flex justify-between">
                  <div className="flex gap-[6px] items-end">
                    <h2 className="text-[#27403F] text-[24px] leading-[26px] font-bold dark:text-white">
                      {distance?.toFixed(2)}
                    </h2>
                    <span className="text-[#787F89] text-[12px] dark:text-white">
                      {t("Miles")}
                    </span>
                  </div>
                  <button className="max-w-[180px] px-2 py-2 bg-[#5DA3E9] text-white text-[14px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px] twrap">
                    {t("Contact Driver")}
                  </button>
                </div>
                <Modal setOpen={setOpen} open={open} />
              </div>
            </div>
          </div>
          <div className="mt-[14px] flex gap-[5px]">
            <div className="bg-white backdrop-blur-[15px] rounded-[8px] border-[1px] border-[#fff] dark:border-none px-[9px] pb-[8px] pt-[21px] w-[60%] dark:bg-box-pattern2">
              <h3 className="text-[#01221A] text-xl font-normal pl-[15px] dark:text-white">
                {t("Vehicle's Profile")}
              </h3>

              <div className="grid grid-cols-4 gap-y-[8px] gap-x-[5px] h-[88%] mt-1">
                <div className="border-[1px] dark:border-none shadow-xl  rounded-[8px] p-[33px_5px_29px_20px] dark:bg-[#202020]">
                  <div className="flex items-center gap-[15px]">
                    <img src="/assets/icons/livespeed.svg" alt="Livespeed" />
                    <p className="text-[#27403F] text-[16px] font-bold dark:text-white">
                      {t("Live speed")}
                    </p>
                  </div>
                  <div className="mt-[20px]">
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {vinRealTimeData.liveSpeed}{" "}
                      <span className="text-[#787F89] text-[12px] font-normal dark:text-white">
                        {t("mph")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="border-[1px] dark:border-none shadow-xl rounded-[8px] p-[33px_5px_29px_20px] dark:bg-[#202020]">
                  <div className="flex items-center gap-[15px]">
                    <img
                      src="/assets/icons/Currentmileage.svg"
                      alt="Currentmileage"
                    />
                    <p className="text-[#27403F] text-[16px] font-bold dark:text-white">
                      {t("Current mileage")}
                    </p>
                  </div>
                  <div className="mt-[20px]">
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {vinRealTimeData.mileage}{" "}
                      <span className="text-[#787F89] text-[12px] font-normal dark:text-white">
                        {t("gpm")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="border-[1px] dark:border-none shadow-xl rounded-[8px] p-[33px_5px_29px_20px] dark:bg-[#202020]">
                  <div className="flex items-center gap-[15px]">
                    <img src="/assets/icons/Traveled.svg" alt="Traveled" />
                    <p className="text-[#27403F] text-[16px] font-bold dark:text-white">
                      {t("Traveled")}
                    </p>
                  </div>
                  <div className="mt-[20px]">
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {vinRealTimeData.distanceTravelled}{" "}
                      <span className="text-[#787F89] text-[12px] font-normal dark:text-white">
                        {t("Miles")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="border-[1px] dark:border-none shadow-xl rounded-[8px] p-[33px_5px_29px_20px] dark:bg-[#202020]">
                  <div className="flex items-center gap-[15px]">
                    <img
                      src="/assets/icons/Fuelconsumed.svg"
                      alt="Fuelconsumed"
                    />
                    <p className="text-[#27403F] text-[16px] font-bold dark:text-white">
                      {t("Fuel consumed")}
                    </p>
                  </div>
                  <div className="mt-[20px]">
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {vinRealTimeData.fuelConsumed}{" "}
                      <span className="text-[#787F89] text-[12px] font-normal dark:text-white">
                        {t("gal")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="border-[1px] dark:border-none shadow-xl rounded-[8px] p-[33px_5px_29px_20px] dark:bg-[#202020]">
                  <div className="flex items-center gap-[15px]">
                    <img
                      src="/assets/icons/VehicleTipe.svg"
                      alt="VehicleTipe"
                    />
                    <p className="text-[#27403F] text-[16px] font-bold dark:text-white">
                      {t("Vehicle Type")}
                    </p>
                  </div>
                  <div className="mt-[20px]">
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {t(`vehicleTypes.${vehicleType}`)}
                    </p>
                  </div>
                </div>
                <div className="border-[1px] dark:border-none shadow-xl rounded-[8px] p-[33px_5px_29px_20px] dark:bg-[#202020]">
                  <div className="">
                    <p className="text-[#27403F] text-[16px] font-bold dark:text-white">
                      {t("State")}
                    </p>
                    <div className="mt-[20px]">
                      <p className="text-dark font-medium text-2xl dark:text-white">
                        {t(`city.${location}`)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-[1px] dark:border-none shadow-xl rounded-[8px] p-[33px_5px_29px_20px] dark:bg-[#202020]">
                  <div className="flex items-center gap-[15px]">
                    <img
                      src="/assets/icons/Totaldrivingtime.svg"
                      alt="Totaldrivingtime"
                    />
                    <p className="text-[#27403F] text-[16px] font-bold dark:text-white">
                      {t("Total driving time")}
                    </p>
                  </div>
                  <div className="mt-[20px] flex gap-[24px]">
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {vinRealTimeData.totalDrivingTime[0].toFixed(0)}{" "}
                      <span className="text-[#787F89] text-[12px] font-normal dark:text-white">
                        {t("h")}
                      </span>
                    </p>
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {vinRealTimeData.totalDrivingTime[1].toFixed(0)}{" "}
                      <span className="text-[#787F89] text-[12px] font-normal dark:text-white">
                        {t("m")}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="border-[1px] dark:border-none shadow-xl rounded-[8px] p-[33px_5px_29px_20px] dark:bg-[#202020]">
                  <div className="flex items-center gap-[15px]">
                    <img src="/assets/icons/Idletime.svg" alt="Idletime" />
                    <p className="text-[#27403F] text-[16px] font-bold dark:text-white">
                      {t("Idle time")}
                    </p>
                  </div>
                  <div className="mt-[20px] flex gap-[24px]">
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {Hours}{" "}
                      <span className="text-[#787F89] text-[12px] font-normal dark:text-white">
                        {t("h")}
                      </span>
                    </p>
                    <p className="text-dark font-medium text-2xl dark:text-white">
                      {minutes.toFixed(2)}{" "}
                      <span className="text-[#787F89] text-[12px] font-normal dark:text-white">
                        {t("m")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white backdrop-blur-[15px] rounded-[8px] border-[1px] border-[#fff] w-[40%] dark:border-none dark:bg-box-pattern2">
              <h3 className="text-[#01221A] text-xl font-normal px-[8px] flex  pb-[8px] pt-[12px] text-center border-b-[#fff]  border-b-[1px] dark:border-none dark:text-white relative">
                <div className="flex justify-between w-full items-center mt-6">
                  <p className="text-transparent"></p>
                  <p className="text-center">{t("Vehicle's Health Profile")}</p>
                  {/* {vinRealTimeData.low_battery_voltage === 1 ? (
                      <>
                        <div className='absolute top-2 right-2'>
                          <select
                            value={selectedLanguage}
                            onChange={(e) => setSelectedLanguage(e.target.value)}
                            className="mr-4 dark:text-white dark:bg-[#202020] text-[15px]"
                          >
                            <option value="English">English</option>
                            <option value="Spanish">Spanish</option>
                            <option value="Portuguese">Portuguese</option>
                            <option value="Chinese">Chinese</option>
                          </select>
                        </div>
                      </>
                    ) : (
                      ''
                    )} */}

                  <div className="pr-1">
                    {vinRealTimeData.low_battery_voltage === 1 ? (
                      <>
                        {isSpeaking ? (
                          <Icon
                            path={mdiStopCircleOutline}
                            size={"25px"}
                            className="dark:text-white cursor-pointer ml-auto"
                            onClick={stopAudio}
                          />
                        ) : (
                          <Icon
                            path={mdiVolumeHigh}
                            size={"25px"}
                            className="dark:text-white cursor-pointer ml-auto"
                            onClick={speak}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </h3>
              <div className="p-[12px_40px]">
                {vinRealTimeData.low_battery_voltage === 1 ? (
                  <div className="border-[#F37E44] border-[1px] p-[10px_12px] h-[4.5rem] flex justify-between items-center gap-[10px] dark:bg-[#F37E44]">
                    <div className="flex items-center gap-[10px]">
                      <img
                        src="/assets/icons/batteryvoltage2.svg"
                        alt="batteryvoltage"
                        className="w-[44px]"
                      />
                      <p className="text-[#F37E44] text-[16px] leading-[18px] dark:text-white">
                        {t("The vehicle is running on")} <br />
                        <span className="text-[#F37E44] font-bold dark:text-white">
                          {t("low battery voltage")}
                        </span>
                      </p>
                    </div>
                    <button className="max-w-[160px] px-2 py-2 bg-[#5DA3E9] text-white text-[14px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px] twrap">
                      {t("Notify garages")}
                    </button>
                  </div>
                ) : (
                  // ""
                  <div className=" p-[10px_12px] h-[4.5rem] flex justify-between items-center gap-[10px] ">
                    {/* <div className="flex items-center gap-[10px]">
                      <img
                        src="/assets/icons/batteryvoltage2.svg"
                        alt="batteryvoltage"
                        className="w-[44px]"
                      />
                      <p className="text-[#F37E44] text-[16px] leading-[18px] dark:text-white">
                        {t("The vehicle is running on")} <br />
                        <span className="text-[#F37E44] font-bold dark:text-white">
                          {t("low battery voltage")}
                        </span>
                      </p>
                    </div>
                    <button className="max-w-[160px] px-2 py-2 bg-[#5DA3E9] text-white text-[14px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px] twrap">
                      {t("Notify garages")}
                    </button> */}
                  </div>
                )}
                {/* <div className='border-[#F2383A] border-[1px] p-[10px_12px] flex justify-between items-center gap-[10px]'>
                  <div className='flex items-center gap-[10px]'>
                    <img src="/assets/icons/batteryvoltage2.svg" alt="batteryvoltage" className='w-[44px]' />
                    <p className={`${vinRealTimeData.low_battery_voltage === 1 ? 'text-[#0DBF4F]' : "text-[#F2383A]"} text-[16px] leading-[18px]`}>The vehicle is running on <br /><span className={`${vinRealTimeData.low_battery_voltage === 1 ? 'text-[#0DBF4F]' : "text-[#F2383A]"} font-bold`}>low battery voltage</span></p>
                  </div>
                  <button className='max-w-[118px] px-2 py-2 bg-[#5DA3E9] text-white text-[14px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px]' >Notify garages<img src="/assets/icons/vector.svg" alt="vector" className='mix-blend-screen absolute top-[-5px] left-[-10px]' /></button>
                </div> */}
                <div className="px-[16px]">
                  <div className="grid grid-cols-2 gap-x-[70px] gap-y-[20px] mt-[20px] relative">
                    <div
                      className={`${
                        tireStatus.tfl.forecastedFlag === 2
                          ? "border-[#EBAE1B] dark:border-none dark:bg-[rgba(235,174,27,0.7)] dark:text-[#444444]"
                          : tireStatus.tfl.forecastedFlag === 3 ||
                            tireStatus.tfl.forecastedFlag === 4
                          ? "border-[#F37E44] dark:border-none dark:bg-[rgba(243,126,68,0.7)] dark:text-[#444444]"
                          : tireStatus.tfl.forecastedFlag === 1 ||
                            tireStatus.tfl.forecastedFlag === 0
                          ? "border-[#0DBF4F] dark:border-none dark:bg-[#3b3b3b]"
                          : ""
                      } bg-[#ffffff61] p-[12px] shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)] border-[1px] relative rounded-[4px]`}
                    >
                      <div className="max-w-[100px]">
                        <h2 className="text-[12px] text-center dark:text-white">
                          {t("Current")}
                        </h2>
                        <p
                          className={`${
                            tireStatus.tfl.forecastedFlag === 2
                              ? "text-[#EBAE1B] dark:text-[#444444]"
                              : tireStatus.tfl.forecastedFlag === 3 ||
                                tireStatus.tfl.forecastedFlag === 4
                              ? "text-[#F37E44] dark:text-[#444444]"
                              : tireStatus.tfl.forecastedFlag === 1 ||
                                tireStatus.tfl.forecastedFlag === 0
                              ? "text-[#0DBF4F]"
                              : ""
                          } text-[16px] text-center`}
                        >
                          {tireStatus.tfl.current}
                          <span className="text-[12px]">{t("Psi")}</span>
                        </p>
                        <h2 className="text-[12px] text-center mt-1 dark:text-white">
                          {t("Next 30 mins")}
                        </h2>
                        <p
                          className={`${
                            tireStatus.tfl.forecastedFlag === 2
                              ? "text-[#EBAE1B] dark:text-[#444444]"
                              : tireStatus.tfl.forecastedFlag === 3 ||
                                tireStatus.tfl.forecastedFlag === 4
                              ? "text-[#F37E44] dark:text-[#444444]"
                              : tireStatus.tfl.forecastedFlag === 1 ||
                                tireStatus.tfl.forecastedFlag === 0
                              ? "text-[#0DBF4F]"
                              : ""
                          } text-[16px] text-center`}
                        >
                          {tireStatus.tfl.thirtyMin}{" "}
                          <span className="text-[12px]">{t("Psi")}</span>
                        </p>
                      </div>
                      {/* <img
                        src={
                          tireStatus.tfl.forecastedFlag === 3 ||
                          tireStatus.tfl.forecastedFlag === 4
                            ? "/assets/images/wheel-orange.svg"
                            : "/assets/images/wheel-white.svg"
                        }
                        alt="wheel-white"
                        className="right-[12px] top-[54%] translate-y-[-50%] absolute w-[18px]"
                      /> */}
                      <img
                        src={
                          tireStatus.tfl.forecastedFlag === 3 ||
                          tireStatus.tfl.forecastedFlag === 4
                            ? "/assets/images/gray-tire.gif"
                            : "/assets/images/gray-tire.gif"
                        }
                        alt="wheel-white"
                        className="right-[-2%] top-[40%] translate-y-[-50%] absolute w-[45px] transfom rotate-[-90deg]"
                      />
                    </div>
                    <div
                      className={`${
                        tireStatus.tfr.forecastedFlag === 2
                          ? "border-[#EBAE1B] dark:border-none dark:bg-[rgba(235,174,27,0.7)] dark:text-[#444444]"
                          : tireStatus.tfr.forecastedFlag === 3 ||
                            tireStatus.tfr.forecastedFlag === 4
                          ? "border-[#F37E44] dark:border-none dark:bg-[rgba(243,126,68,0.7)] dark:text-[#444444]"
                          : tireStatus.tfr.forecastedFlag === 1 ||
                            tireStatus.tfr.forecastedFlag === 0
                          ? "border-[#0DBF4F] dark:border-none dark:bg-[#3b3b3b]"
                          : ""
                      } bg-[#ffffff61] p-[12px] shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)] border-[1px] relative rounded-[4px]`}
                    >
                      <div className="max-w-[100px] ml-auto">
                        <h2 className="text-[12px] text-center dark:text-white">
                          {t("Current")}
                        </h2>
                        <p
                          className={`${
                            tireStatus.tfr.forecastedFlag === 2
                              ? "text-[#EBAE1B] dark:text-[#444444]"
                              : tireStatus.tfr.forecastedFlag === 3 ||
                                tireStatus.tfr.forecastedFlag === 4
                              ? "text-[#F37E44] dark:text-[#444444]"
                              : tireStatus.tfr.forecastedFlag === 1 ||
                                tireStatus.tfr.forecastedFlag === 0
                              ? "text-[#0DBF4F]"
                              : ""
                          } text-[16px] text-center`}
                        >
                          {tireStatus.tfr.current}{" "}
                          <span className="text-[12px]">{t("Psi")}</span>
                        </p>
                        <h2 className="text-[12px] text-center mt-1 dark:text-white">
                          {t("Next 30 mins")}
                        </h2>
                        <p
                          className={`${
                            tireStatus.tfr.forecastedFlag === 2
                              ? "text-[#EBAE1B] dark:text-[#444444]"
                              : tireStatus.tfr.forecastedFlag === 3 ||
                                tireStatus.tfr.forecastedFlag === 4
                              ? "text-[#F37E44] dark:text-[#444444]"
                              : tireStatus.tfr.forecastedFlag === 1 ||
                                tireStatus.tfr.forecastedFlag === 0
                              ? "text-[#0DBF4F]"
                              : ""
                          } text-[16px] text-center`}
                        >
                          {tireStatus.tfr.thirtyMin}{" "}
                          <span className="text-[12px]">{t("Psi")}</span>
                        </p>
                      </div>
                      {/* <img
                        src={
                          tireStatus.tfr.forecastedFlag === 3 ||
                          tireStatus.tfr.forecastedFlag === 4
                            ? "/assets/images/wheel-orange.svg"
                            : "/assets/images/wheel-white.svg"
                        }
                        alt="wheel-white"
                        className="left-[12px] top-[54%] translate-y-[-50%] absolute w-[18px]"
                      /> */}

                      <img
                        src={
                          tireStatus.tfl.forecastedFlag === 3 ||
                          tireStatus.tfl.forecastedFlag === 4
                            ? "/assets/images/gray-tire.gif"
                            : "/assets/images/gray-tire.gif"
                        }
                        alt="wheel-white"
                        className="left-[-2%] top-[40%] translate-y-[-50%] absolute w-[45px] transfom rotate-[-90deg]"
                      />
                    </div>
                    <div
                      className={`${
                        tireStatus.trl.forecastedFlag === 2
                          ? "border-[#EBAE1B] dark:border-none dark:bg-[rgba(235,174,27,0.7)] dark:text-[#444444]"
                          : tireStatus.trl.forecastedFlag === 3 ||
                            tireStatus.trl.forecastedFlag === 4
                          ? "border-[#F37E44] dark:border-none dark:bg-[rgba(243,126,68,0.7)] dark:text-[#444444]"
                          : tireStatus.trl.forecastedFlag === 1 ||
                            tireStatus.trl.forecastedFlag === 0
                          ? "border-[#0DBF4F] dark:border-none dark:bg-[#3b3b3b]"
                          : ""
                      } bg-[#ffffff61] p-[12px] shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)] border-[1px] relative rounded-[4px]`}
                    >
                      <div className="max-w-[100px]">
                        <h2 className="text-[12px] text-center dark:text-white">
                          {t("Current")}
                        </h2>
                        <p
                          className={`${
                            tireStatus.trl.forecastedFlag === 2
                              ? "text-[#EBAE1B] dark:text-[#444444]"
                              : tireStatus.trl.forecastedFlag === 3 ||
                                tireStatus.trl.forecastedFlag === 4
                              ? "text-[#F37E44] dark:text-[#444444]"
                              : tireStatus.trl.forecastedFlag === 1 ||
                                tireStatus.trl.forecastedFlag === 0
                              ? "text-[#0DBF4F]"
                              : ""
                          } text-[16px] text-center`}
                        >
                          {tireStatus.trl.current}{" "}
                          <span className="text-[12px]">{t("Psi")}</span>
                        </p>
                        <h2 className="text-[12px] text-center mt-1 dark:text-white">
                          {t("Next 30 mins")}
                        </h2>
                        <p
                          className={`${
                            tireStatus.trl.forecastedFlag === 2
                              ? "text-[#EBAE1B] dark:text-[#444444]"
                              : tireStatus.trl.forecastedFlag === 3 ||
                                tireStatus.trl.forecastedFlag === 4
                              ? "text-[#F37E44] dark:text-[#444444]"
                              : tireStatus.trl.forecastedFlag === 1 ||
                                tireStatus.trl.forecastedFlag === 0
                              ? "text-[#0DBF4F]"
                              : ""
                          } text-[16px] text-center`}
                        >
                          {tireStatus.trl.thirtyMin}{" "}
                          <span className="text-[12px]">{t("Psi")}</span>
                        </p>
                      </div>
                      {/* <img
                        src={
                          tireStatus.trl.forecastedFlag === 3 ||
                          tireStatus.trl.forecastedFlag === 4
                            ? "/assets/images/wheel-orange.svg"
                            : "/assets/images/wheel-white.svg"
                        }
                        alt="wheel-white"
                        className="right-[12px] top-[54%] translate-y-[-50%] absolute w-[18px]"
                      /> */}

                      <img
                        src={
                          tireStatus.tfl.forecastedFlag === 3 ||
                          tireStatus.tfl.forecastedFlag === 4
                            ? "/assets/images/gray-tire.gif"
                            : "/assets/images/gray-tire.gif"
                        }
                        alt="wheel-white"
                        className="right-[-2%] top-[40%] translate-y-[-50%] absolute w-[45px] transfom rotate-[-90deg]"
                      />
                    </div>
                    <div
                      className={`${
                        tireStatus.trr.forecastedFlag === 2
                          ? "border-[#EBAE1B] dark:border-none dark:bg-[rgba(235,174,27,0.7)] dark:text-[#444444]"
                          : tireStatus.trr.forecastedFlag === 3 ||
                            tireStatus.trr.forecastedFlag === 4
                          ? "border-[#F37E44] dark:border-none dark:bg-[rgba(243,126,68,0.7)] dark:text-[#444444]"
                          : tireStatus.trr.forecastedFlag === 1 ||
                            tireStatus.trr.forecastedFlag === 0
                          ? "border-[#0DBF4F] dark:border-none dark:bg-[#3b3b3b]"
                          : ""
                      } bg-[#ffffff61] p-[12px] shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)] border-[1px] relative rounded-[4px]`}
                    >
                      <div className="max-w-[100px] ml-auto">
                        <h2 className="text-[12px] text-center dark:text-white">
                          {t("Current")}
                        </h2>
                        <p
                          className={`${
                            tireStatus.trr.forecastedFlag === 2
                              ? "text-[#EBAE1B] dark:text-[#444444]"
                              : tireStatus.trr.forecastedFlag === 3 ||
                                tireStatus.trr.forecastedFlag === 4
                              ? "text-[#F37E44] dark:text-[#444444]"
                              : tireStatus.trr.forecastedFlag === 1 ||
                                tireStatus.trr.forecastedFlag === 0
                              ? "text-[#0DBF4F]"
                              : ""
                          } text-[16px] text-center`}
                        >
                          {tireStatus.trr.current}{" "}
                          <span className="text-[12px]">{t("Psi")}</span>
                        </p>
                        <h2 className="text-[12px] text-center mt-1 dark:text-white">
                          {t("Next 30 mins")}
                        </h2>
                        <p
                          className={`${
                            tireStatus.trr.forecastedFlag === 2
                              ? "text-[#EBAE1B] dark:text-[#444444]"
                              : tireStatus.trr.forecastedFlag === 3 ||
                                tireStatus.trr.forecastedFlag === 4
                              ? "text-[#F37E44] dark:text-[#444444]"
                              : tireStatus.trr.forecastedFlag === 1 ||
                                tireStatus.trr.forecastedFlag === 0
                              ? "text-[#0DBF4F]"
                              : ""
                          } text-[16px] text-center`}
                        >
                          {tireStatus.trr.thirtyMin}{" "}
                          <span className="text-[12px]">{t("Psi")}</span>
                        </p>
                      </div>
                      {/* <img
                        src={
                          tireStatus.trr.forecastedFlag === 3 ||
                          tireStatus.trr.forecastedFlag === 4
                            ? "/assets/images/wheel-orange.svg"
                            : "/assets/images/wheel-white.svg"
                        }
                        alt="wheel-white"
                        className="left-[12px] top-[54%] translate-y-[-50%] absolute w-[18px]"
                      /> */}
                      <img
                        src={
                          tireStatus.tfl.forecastedFlag === 3 ||
                          tireStatus.tfl.forecastedFlag === 4
                            ? "/assets/images/gray-tire.gif"
                            : "/assets/images/gray-tire.gif"
                        }
                        alt="wheel-white"
                        className="left-[-2%] top-[40%] translate-y-[-50%] absolute w-[45px] transfom rotate-[-90deg]"
                      />
                    </div>
                    {/* <img
                      src="/assets/images/car.png"
                      alt="car"
                      className="absolute w-[128px] z-[-1] left-[50%] translate-x-[-50%] opacity-[.4] top-[6px] "
                    /> */}
                    <img
                      src="/assets/images/car6.png"
                      alt="car"
                      className="absolute w-[278px] z-[-1] left-[50%] translate-x-[-50%]  top-[18%] transform rotate-[90deg]"
                    />
                    {vinRealTimeData.low_battery_voltage === 1 ? (
                      <div className='bg-[#F37E44] w-[24px] h-[24px] absolute left-[50%] translate-x-[-50%] top-[30px] after:absolute after:content-[""] after:w-[1px] after:h-[50px] after:bg-[#F37E44] after:top-[-50px] after:left-[50%] after:translate-x-[-50%] flex justify-center items-center p-[2px]'>
                        <img
                          src="/assets/icons/batteryvoltage1.svg"
                          alt="batteryvoltage"
                          className="grayscale"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className='bg-[#db0a2a] w-[24px] h-[24px] absolute left-[50%] translate-x-[-50%] top-[50px] after:absolute after:content-[""] after:w-[1px] after:h-[70px] after:bg-[#db0a2a] after:top-[-70px] after:left-[50%] after:translate-x-[-50%] flex justify-center items-center p-[2px]'>
                        <img src="/assets/icons/batteryvoltage1.svg" alt="batteryvoltage" className='grayscale' />
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='bg-[#F1F7F6] backdrop-blur-[15px] rounded-[8px] border-[1px] border-[#fff] w-[35%]'>
              <h3 className='text-[#01221A] text-[24px] font-normal px-[8px] pb-[9px] pt-[24px]'>Safety & predictive maintenance</h3>
              <div className='border-t-[1px] border-t-[#FFFFFF]'></div>
              <div className='px-[8px] pt-[6px] pb-[7px]'>
                <div className='rounded-[8px] px-4 py-4 bg-[#fff] shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)] p-[9px_19px_6px_4px]'>
                  <div className='flex items-center gap-[8px]'>
                    <img src="/assets/icons/Vehiclesrunning.svg" alt="Vehiclesrunning" />
                    <h6 className={`${vinRealTimeData.low_tire_pressure === 1 ? 'text-[#0DBF4F]' : "text-[#F2383A]"} text-[18px] font-normal`}>Vehicle running on <span className={`${vinRealTimeData.low_tire_pressure === 1 ? 'text-[#0DBF4F]' : "text-[#F2383A]"} text-[18px] font-bold`}>low tire pressure</span></h6>
                  </div>
                  <div className='pl-[42px] mt-4 flex items-end justify-center'>
                    {vinRealTimeData.low_tire_pressure === 1
                      ?
                      <button className='max-w-[138px] px-4 py-2 bg-[#CDCBCB99] text-white text-[16px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px]'>Alert Driver</button>
                      :
                      <button className='max-w-[138px] px-4 py-2 bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative gap-[20px]'>Alert Driver <img src="/assets/icons/vector.svg" alt="vector" className='mix-blend-screen absolute top-[-5px] left-[-6px]' /></button>
                    }
                  </div>
                </div>
              </div>
              <div className='border-t-[1px] border-t-[#FFFFFF]'></div>
              <div className='px-[8px] pt-[13px] pb-[9px]'>
                <div className='rounded-[8px] bg-[#fff] px-4 py-4 shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)] p-[9px_19px_6px_4px]'>
                  <div className='flex items-center gap-[8px]'>
                    <img src="/assets/icons/batteryvoltage2.svg" alt="batteryvoltage" />
                    <h6 className={`${vinRealTimeData.low_battery_voltage === 1 ? 'text-[#0DBF4F]' : "text-[#F2383A]"} text-[18px] font-normal`}>Vehicle running on <span className={`${vinRealTimeData.low_battery_voltage === 1 ? 'text-[#0DBF4F]' : "text-[#F2383A]"} text-[18px] font-bold`}>low battery voltage</span></h6>
                  </div>
                  <div className='pl-[42px] mt-4 flex items-end justify-center'>
                    {vinRealTimeData.low_battery_voltage === 1
                      ?
                      <button className='max-w-[138px] px-4 py-2 bg-[#CDCBCB99] text-white text-[16px] font-bold w-full rounded-md flex items-center justify-center relative gap-[20px]'>Notify garages</button>
                      :
                      <button className='max-w-[138px] px-4 py-2 bg-[#5DA3E9] text-white text-[16px] font-bold rounded-md flex items-center justify-center w-full relative'>Notify garages <img src="/assets/icons/vector.svg" alt="vector" className='mix-blend-screen absolute top-[-3px] left-[-6px]' /></button>
                    }
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Popup
        openTire={openTire}
        setOpenTire={setOpenTire}
        vinRealTimeData={vinRealTimeData}
        language={selectedLanguage}
      />
    </Layout>
  );
};

export default VehiclesTwins;
