import {
  mdiAccountCircle,
  mdiBellOutline,
  mdiChevronDown,
  mdiCogOutline,
  mdiPower,
  mdiViewDashboardOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { UseContextData } from "../../context/dateContext";
import ToggleSwitch from "../switch/ToggleSwitch";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkTheme, setIsDarkTheme, user, setUser } = UseContextData();
  const { t } = useTranslation()

  const handleLogout = () => {
    Cookies.remove("user");
    navigate("/login");
  };

  // const [user, setUser] = useState(null);
  useEffect(() => {
    const storedUser = Cookies.get("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <>
      <div className="fixed inset-y-0 max-w-[300px] w-full bg-white dark:bg-[#252525] shadow-[0px_2px_10px_0px_rgba(0,0,0,0.05)] z-20 p-4 pt-0">
        <div className="flex flex-col h-full">
          <div className="flex-grow">
            <div className="flex justify-center items-center">
              <img
                src="/assets/images/dynamo-logo.png"
                alt="logo"
                className="h-[60px] my-2"
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <div>
              <div>
                <div
                  className="border-0 p-3 bg-[#E6F8F2] dark:bg-[#aaaaaa] rounded-lg flex items-center justify-between cursor-pointer"
                  onClick={() => setOpen(!open)}
                >
                  <div className="flex items-center ">
                    {/* <img src="/assets/icons/traffic-event.svg" alt="traffic-event" className='mr-2 w-5 h-5' /> */}
                    <img
                      src={` ${
                        isDarkTheme
                          ? "/assets/icons/traffic-event 2.svg"
                          : "/assets/icons/traffic-event.svg"
                      }`}
                      alt="traffic-event"
                      className="mr-2 w-5 h-5"
                    />
                    <div className="text-primary dark:text-white text-base font-bold">
                      {" "}
                      {t('Autonet')}
                    </div>
                  </div>
                  <Icon
                    path={mdiChevronDown}
                    size={1}
                    id={1}
                    className={`${
                      open === 1 ? "rotate-180" : ""
                    } text-primary dark:text-white transition-all duration-100`}
                  />
                </div>
                {open ? (
                  <ul className="mt-4 ml-10">
                    <li
                      className={`text-base ${
                        ["/fleet_view", "/fleet_view_map"].includes(
                          location.pathname
                        )
                          ? "text-primary font-bold dark:text-white"
                          : "text-dark1 dark:text-[#797979]"
                      }  mt-4 cursor-pointer dark:text-[#797979]`}
                      onClick={() => {
                        navigate("/fleet_view");
                      }}
                    >
                      {t('Fleet Overview')}
                    </li>
                    <li
                      className={`mt-4 ${
                        ["/fuel_net"].includes(location.pathname)
                          ? "text-primary font-bold dark:text-white"
                          : "text-dark1 dark:text-[#797979]"
                      } text-dark1 text-base cursor-pointer dark:text-[#797979]`}
                      onClick={() => {
                        navigate("/fuel_net");
                      }}
                    >
                      {t('List of Vehicles')}
                    </li>
                    <li
                      className={`mt-4 ${
                        ["/digital_twins"].includes(location.pathname)
                          ? "text-primary font-bold dark:text-white"
                          : "text-dark1 dark:text-[#797979]"
                      } text-dark1 text-base cursor-pointer dark:text-[#797979]`}
                      onClick={() => {
                        navigate("/digital_twins");
                      }}
                    >
                      {t('Digital Twin')}
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
              {isDarkTheme ? (
                <>
                  <div
                    className={`px-3 text-base ${
                      ["/canvas"].includes(location.pathname)
                        ? "text-primary font-bold dark:text-white"
                        : "text-dark1 dark:text-[#797979]"
                    } flex items-center text-base mt-4 cursor-pointer`}
                    onClick={() => {
                      navigate("/canvas");
                    }}
                  >
                    <img
                      src={` ${
                        ["/canvas"].includes(location.pathname)
                          ? "/assets/icons/canvas4.png"
                          : "/assets/icons/canvas3.png"
                      }`}
                      alt="canvas"
                      className="mr-2 -ml-1 inline"
                    />
                    {t('Canvas')}
                  </div>
                  <div
                    className={`px-3 text-base ${
                      ["/batterynet"].includes(location.pathname)
                        ? "text-primary font-bold dark:text-white"
                        : "text-dark1 dark:text-[#797979]"
                    } flex items-center text-base mt-4 cursor-pointer`}
                    onClick={() => {
                      navigate("/batterynet");
                    }}
                  >
                    <img
                      src={` ${
                        ["/batterynet"].includes(location.pathname)
                          ? "/assets/icons/bettry4.svg"
                          : "/assets/icons/bettry3.svg"
                      }`}
                      alt="bettry"
                      className="mr-2 inline"
                    />
                    {t('Batteries')}
                  </div>
                  <div
                    className={`px-3 text-base ${
                      ["/econet"].includes(location.pathname)
                        ? "text-primary font-bold dark:text-white"
                        : "text-dark1 dark:text-[#797979]"
                    } flex items-center text-base mt-4 cursor-pointer`}
                    onClick={() => {
                      navigate("/econet");
                    }}
                  >
                    <img
                      src={` ${
                        ["/econet"].includes(location.pathname)
                          ? "/assets/icons/econet4.svg"
                          : "/assets/icons/econet3.svg"
                      }`}
                      alt="econet"
                      className="mr-2 inline"
                    />
                    {t('Econet')}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`px-3 text-base ${
                      ["/canvas"].includes(location.pathname)
                        ? "text-primary font-bold"
                        : "text-dark1"
                    } flex items-center text-base mt-4 cursor-pointer`}
                    onClick={() => {
                      navigate("/canvas");
                    }}
                  >
                    <img
                      src={` ${
                        ["/canvas"].includes(location.pathname)
                          ? "/assets/icons/canvas2.png"
                          : "/assets/icons/canvas.png"
                      }`}
                      alt="canvas"
                      className="mr-2 -ml-1 inline"
                    />
                    {t('Canvas')}
                  </div>

                  <div
                    className={`px-3 text-base ${
                      ["/batterynet"].includes(location.pathname)
                        ? "text-primary font-bold"
                        : "text-dark1"
                    } flex items-center text-base mt-4 cursor-pointer`}
                    onClick={() => {
                      navigate("/batterynet");
                    }}
                  >
                    <img
                      src={` ${
                        ["/batterynet"].includes(location.pathname)
                          ? "/assets/icons/bettry2.svg"
                          : "/assets/icons/bettry.svg"
                      }`}
                      alt="bettry"
                      className="mr-2 inline"
                    />
                    {t('Batteries')}
                  </div>
                  <div
                    className={`px-3 text-base ${
                      ["/econet"].includes(location.pathname)
                        ? "text-primary font-bold"
                        : "text-dark1"
                    } flex items-center text-base mt-4 cursor-pointer`}
                    onClick={() => {
                      navigate("/econet");
                    }}
                  >
                    <img
                      src={` ${
                        ["/econet"].includes(location.pathname)
                          ? "/assets/icons/econet2.svg"
                          : "/assets/icons/econet.svg"
                      }`}
                      alt="econet"
                      className="mr-2 inline"
                    />
                    {t('Econet')}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="p-4">
            <div className="px-3 text-base text-dark1 flex items-center font-medium mt-4 cursor-pointer dark:text-[#797979]">
              <Icon
                path={mdiBellOutline}
                size={"20px"}
                className="mr-2 inline text-dark1 dark:text-[#797979]"
              />
              {t('Notifications')}
            </div>
            <div className="px-3 text-base text-dark1 flex items-center font-medium mt-4 cursor-pointer dark:text-[#797979]">
              <Icon
                path={mdiViewDashboardOutline}
                size={"20px"}
                className="mr-2 inline text-dark1 dark:text-[#797979]"
              />
              {t('Module')}
            </div>
            <div className="px-3 text-base text-dark1 flex items-center font-medium mt-4 cursor-pointer dark:text-[#797979]">
              <Icon
                path={mdiCogOutline}
                size={"20px"}
                className="mr-2 inline text-dark1 dark:text-[#797979]"
              />
              {t('Settings')}
            </div>
            <div className="px-3 text-base text-dark1 flex justify-between items-center font-medium mt-4 cursor-pointer">
              <div className="flex items-center dark:text-[#797979]">
                {/* <img src={"assets/images/user.png"} alt="user" className='mr-2' /> */}
                <Icon
                  path={mdiAccountCircle}
                  className="mr-2 dark:text-[#797979]"
                  size={1}
                />
                {t(user?.username)}
              </div>
              <Icon
                path={mdiPower}
                size={"20px"}
                onClick={handleLogout}
                className="text-[red]"
                data-tooltip-id="alert-msg"
              />
              <ReactTooltip
                id="alert-msg"
                className="z-20 !bg-black shadow-xl rou"
                place="top"
                content={
                  <div className="flex items-start gap-2">
                    <div className="font-bold text-white">{t('Logout')}</div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
